import React from 'react';
import { DribbleLogo, FacebookLogo, InstagramLogo } from '../../../assets/svg';
import { IconLink } from '../../IconLink';
import { StyledFlexRow } from '../../layout';
import { styled } from 'styled-components';

const data = [
  {
    id: 1,
    link: 'https://dribbble.com/mockupbro',
    icon: <DribbleLogo />,
  },
  {
    id: 2,
    link: 'https://www.instagram.com/mockupbro/',
    icon: <InstagramLogo />,
  },
  {
    id: 3,
    link: 'https://www.facebook.com/mockupbro',
    icon: <FacebookLogo />,
  },
];

export const FooterIcons = () => {
  return (
    <Container>
      {data.map((item) => (
        <IconLink key={item.id} href={item.link} newTab>
          {item.icon}
        </IconLink>
      ))}
    </Container>
  );
};
const Container = styled(StyledFlexRow)`
  gap: 20px;
`;
