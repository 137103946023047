export const navigationMenu = [
  {
    id: 0,
    name: 'Devices',
    link: 'https://mockupbro.com/mockups/devices',
  },
  {
    id: 1,
    name: 'Print',
    link: 'https://mockupbro.com/mockups/print',
  },
  {
    id: 2,
    name: 'Apparel',
    link: 'https://mockupbro.com/mockups/apparel',
  },
  {
    id: 3,
    name: 'Packaging',
    link: 'https://mockupbro.com/mockups/package',
  },
  {
    id: 4,
    name: 'Home & Decor',
    link: 'https://mockupbro.com/mockups/home-and-decor',
  },
  {
    id: 5,
    name: 'Social Media',
    link: 'https://mockupbro.com/mockups/social-media',
  },
];

export const innerNavigation = [
  {
    id: 10,
    name: 'Home',
    link: '/',
  },
  {
    id: 1,
    name: 'Facebook',
    link: '/facebook',
  },
  {
    id: 2,
    name: 'Instagram',
    link: '/instagram',
  },
];
