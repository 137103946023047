import React from 'react';

export const Logo: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      version="1.1"
      id="mockupbroLogo"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="143px"
      height="42px"
      viewBox="0 0 143 42">
      <g>
        <path
          fill="#EDF2F6"
          d="M57.754,25.679L55.71,18.48h-0.062c0.096,1.226,0.144,2.178,0.144,2.857v4.341h-2.393v-9.994h3.596
		l2.085,7.096h0.055l2.044-7.096h3.603v9.994H62.3v-4.382c0-0.229,0.004-0.48,0.011-0.759c0.007-0.278,0.037-0.959,0.092-2.044
		h-0.062l-2.017,7.185H57.754z"></path>{' '}
        <path
          fill="#EDF2F6"
          d="M75.771,20.668c0,1.682-0.413,2.96-1.237,3.835c-0.825,0.875-2.033,1.313-3.623,1.313
		c-1.568,0-2.77-0.44-3.606-1.319c-0.836-0.879-1.254-2.16-1.254-3.842c0-1.664,0.416-2.934,1.247-3.811
		c0.832-0.877,2.041-1.316,3.627-1.316c1.59,0,2.796,0.435,3.616,1.306S75.771,18.982,75.771,20.668z M68.895,20.668
		c0,1.932,0.672,2.898,2.017,2.898c0.684,0,1.19-0.234,1.521-0.704c0.331-0.47,0.496-1.2,0.496-2.194
		c0-0.998-0.168-1.735-0.503-2.211s-0.835-0.714-1.5-0.714C69.571,17.742,68.895,18.717,68.895,20.668z"></path>{' '}
        <path
          fill="#EDF2F6"
          d="M81.762,17.756c-0.643,0-1.145,0.263-1.504,0.79c-0.36,0.526-0.54,1.252-0.54,2.177
		c0,1.924,0.731,2.885,2.194,2.885c0.441,0,0.87-0.062,1.285-0.185c0.414-0.123,0.831-0.271,1.251-0.444v2.283
		c-0.834,0.369-1.777,0.554-2.83,0.554c-1.509,0-2.665-0.438-3.47-1.313c-0.804-0.875-1.206-2.14-1.206-3.794
		c0-1.035,0.194-1.944,0.584-2.728s0.95-1.387,1.682-1.808s1.592-0.632,2.581-0.632c1.08,0,2.112,0.235,3.097,0.704l-0.827,2.126
		c-0.369-0.173-0.738-0.319-1.107-0.438S82.186,17.756,81.762,17.756z"></path>{' '}
        <path
          fill="#EDF2F6"
          d="M94.919,25.679H91.87l-1.976-3.821l-0.8,0.479v3.343h-2.714v-9.994h2.714v4.341
		c0.137-0.269,0.413-0.695,0.827-1.278l2.099-3.063h2.953l-3.151,4.478L94.919,25.679z"></path>{' '}
        <path
          fill="#EDF2F6"
          d="M105.023,15.685V21.7c0,1.308-0.37,2.321-1.111,3.038c-0.74,0.719-1.808,1.077-3.202,1.077
		c-1.363,0-2.41-0.349-3.142-1.046s-1.097-1.699-1.097-3.008v-6.077h2.714v5.865c0,0.706,0.132,1.22,0.396,1.538
		c0.264,0.318,0.653,0.479,1.169,0.479c0.551,0,0.951-0.158,1.199-0.475c0.249-0.317,0.373-0.836,0.373-1.556v-5.852H105.023z"></path>{' '}
        <path
          fill="#EDF2F6"
          d="M113.942,18.863c0,1.117-0.329,1.979-0.987,2.588c-0.659,0.607-1.594,0.912-2.807,0.912h-0.759v3.315h-2.7
		v-9.994h3.459c1.263,0,2.211,0.276,2.844,0.827C113.626,17.063,113.942,17.847,113.942,18.863z M109.39,20.162h0.492
		c0.406,0,0.729-0.114,0.968-0.342s0.358-0.542,0.358-0.943c0-0.674-0.373-1.012-1.121-1.012h-0.697V20.162z"></path>{' '}
        <path
          fill="#5E72E4"
          d="M115.489,15.685h3.486c1.358,0,2.366,0.202,3.025,0.605c0.658,0.403,0.987,1.031,0.987,1.883
		c0,0.561-0.144,1.039-0.431,1.436s-0.665,0.652-1.135,0.766v0.068c0.62,0.164,1.069,0.438,1.347,0.82
		c0.278,0.383,0.417,0.88,0.417,1.49c0,0.907-0.341,1.621-1.021,2.144c-0.682,0.521-1.61,0.782-2.786,0.782h-3.89V15.685z
		 M118.189,19.52h0.813c0.388,0,0.688-0.08,0.899-0.239s0.317-0.396,0.317-0.711c0-0.561-0.424-0.841-1.271-0.841h-0.759V19.52z
		 M118.189,21.495v2.099h0.95c0.844,0,1.265-0.355,1.265-1.066c0-0.332-0.112-0.588-0.338-0.766s-0.553-0.267-0.981-0.267H118.189z"></path>{' '}
        <path
          fill="#5E72E4"
          d="M127.39,22.042v3.637h-2.7v-9.994h3.274c2.716,0,4.074,0.984,4.074,2.953c0,1.158-0.565,2.054-1.695,2.687
		l2.912,4.354h-3.063l-2.119-3.637H127.39z M127.39,20.012h0.506c0.943,0,1.415-0.417,1.415-1.251c0-0.688-0.463-1.032-1.388-1.032
		h-0.533V20.012z"></path>{' '}
        <path
          fill="#5E72E4"
          d="M143,20.668c0,1.682-0.412,2.96-1.237,3.835c-0.824,0.875-2.032,1.313-3.623,1.313
		c-1.567,0-2.77-0.44-3.605-1.319c-0.837-0.879-1.255-2.16-1.255-3.842c0-1.664,0.416-2.934,1.248-3.811
		c0.831-0.877,2.04-1.316,3.626-1.316c1.591,0,2.796,0.435,3.616,1.306S143,18.982,143,20.668z M136.123,20.668
		c0,1.932,0.673,2.898,2.017,2.898c0.684,0,1.19-0.234,1.521-0.704c0.33-0.47,0.495-1.2,0.495-2.194
		c0-0.998-0.167-1.735-0.502-2.211s-0.835-0.714-1.501-0.714C136.8,17.742,136.123,18.717,136.123,20.668z"></path>{' '}
        <g>
          <g>
            <path
              fill="#5E72E4"
              d="M0,14.127c0-2.339,1.658-5.209,3.683-6.379l11.902-6.872c2.026-1.169,5.341-1.169,7.367,0l11.902,6.872
				c2.026,1.169,3.683,4.04,3.683,6.379v13.744c0,2.34-1.657,5.211-3.683,6.379l-11.902,6.873c-2.026,1.17-5.341,1.17-7.367,0
				L3.683,34.25C1.658,33.082,0,30.211,0,27.871V14.127z"></path>
          </g>
        </g>{' '}
        <g>
          <path
            fill="#161F32"
            d="M17.916,8.771l-4.175,2.448l-3.783,2.215c-1.092,0.641-1.985,2.199-1.985,3.465v9.173
			c0,2.319,1.637,3.258,3.639,2.086c0,0,0.001,0,0.002,0l6.303-3.695h0.002l1.652-0.969c1.092-0.641,1.985-2.199,1.985-3.464v-9.173
			C21.556,8.537,19.918,7.598,17.916,8.771z"></path>{' '}
          <path
            fill="#FB5D59"
            d="M22.438,11.36l-4.176,2.447l-3.782,2.217c-1.092,0.639-1.985,2.198-1.985,3.464v9.172
			c0,2.32,1.637,3.259,3.638,2.088c0.001,0,0.002-0.002,0.002-0.002l6.304-3.693l0.001-0.001l1.653-0.97
			c1.092-0.639,1.985-2.197,1.985-3.463v-9.173C26.078,11.125,24.44,10.186,22.438,11.36z"></path>{' '}
          <path
            fill="#FFFFFF"
            d="M26.961,14.205l-4.176,2.447l-3.782,2.217c-1.093,0.639-1.985,2.198-1.985,3.463v9.174
			c0,2.318,1.637,3.258,3.639,2.086h0.001l6.304-3.695h0.001l1.653-0.969c1.092-0.641,1.985-2.199,1.985-3.465V16.29
			C30.601,13.97,28.963,13.031,26.961,14.205z"></path>
        </g>
      </g>
    </svg>
  );
};
