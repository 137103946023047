import React, { useCallback } from 'react';
import { DeviceType, FacebookImages } from '../../../types';
import { styled } from 'styled-components';
import { Select } from '../../select';
import { CarouselImage } from './CarouselImage';
import { useFacebook } from 'context';
import { FileInput } from 'components/FileInput';
import { CarouselPlaceholderHeadline } from './CarouselPlaceholderHeadline';
import { CarouselPlaceholderDescription } from './CarouselPlaceholderDescription';
import { PlusIcon } from 'assets';

export const CarouselPlaceholder = () => {
  const { editPlaceholderNewsFeedItem, placeholderData, addCarouselItem, deviceType } =
    useFacebook();
  const { newsFeedMainImage } = placeholderData;
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const imageHeightHandler = useCallback(() => {
    return {
      style: {
        height: deviceType === DeviceType.Desktop ? '300px' : '230px',
      },
    };
  }, [deviceType]);

  return (
    <Wrapper>
      <FileInput
        ref={fileInputRef}
        onChange={(e) => {
          editPlaceholderNewsFeedItem(FacebookImages.NewsFeedMainImage, e.target.files![0]);
          addCarouselItem(e.target.files![0]);
        }}
      />
      <CarouselWrapper {...imageHeightHandler()} onClick={() => fileInputRef.current?.click()}>
        {newsFeedMainImage ? (
          <CarouselImage image={newsFeedMainImage} />
        ) : (
          <PlusIconWrapper>
            <PlusIcon />
            <PlusIconText>Add image</PlusIconText>
          </PlusIconWrapper>
        )}
      </CarouselWrapper>
      <BottomContent>
        <InnerWrapper>
          <CarouselPlaceholderHeadline />
          <CarouselPlaceholderDescription />
        </InnerWrapper>
        <Select
          placeholder
          value={{
            id: placeholderData.newsFeedSelectValue.id,
            label: placeholderData.newsFeedSelectValue.label,
            carouselItemId: placeholderData.newsFeedSelectValue.id,
          }}
        />
      </BottomContent>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
    border: 1px solid #ccc;
    border-radius: ${theme.borderRadius.big};
    overflow: hidden;
    margin-right: ${theme.spacing.spacing10};
    position: relative;
    background: ${theme.colors.white}
`
);

const BottomContent = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.grayMid};
    padding: ${theme.spacing.spacing12} ${theme.spacing.spacing16};
    border-bottom: ${theme.borderWidth.small} ${theme.borderStyle.solid} ${theme.colors.grayMid};
    align-items: center;
    gap: ${theme.spacing.spacing10};
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    justify-content: space-between;
`
);

const InnerWrapper = styled.div(
  () => `
    display: flex;
    flex-direction: column;
`
);

const CarouselWrapper = styled.div(
  () => `
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
);

const PlusIconWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  color: ${theme.colors.grayNeutral};
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSizes.extra};
  gap: ${theme.spacing.spacing5};
`
);

const PlusIconText = styled.p(
  ({ theme }) => `
  color: ${theme.colors.grayNeutral};
  font-size: ${theme.fontSizes.extra}
`
);
