import React from 'react';
import { IconInterface } from 'types';

export const PenIcon: React.FC<React.SVGProps<IconInterface>> = ({ width = 44, height = 44 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="rgba(0, 0, 0, 0.5)" rx="21" ry="21" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9988 28.6732V32.3888C10.9988 32.731 11.2677 32.9999 11.6099 32.9999H15.3254C15.4843 32.9999 15.6432 32.9388 15.7532 32.8166L29.0999 19.4821L24.5166 14.8988L11.1821 28.2332C11.0599 28.3554 10.9988 28.5021 10.9988 28.6732ZM32.6443 15.9377C33.121 15.461 33.121 14.691 32.6443 14.2143L29.7843 11.3543C29.3077 10.8777 28.5377 10.8777 28.061 11.3543L25.8243 13.591L30.4077 18.1743L32.6443 15.9377Z"
        fill="white"></path>
      <circle cx="22" cy="22" r="21" stroke="white" strokeWidth="2"></circle>
    </svg>
  );
};
