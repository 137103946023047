import React from 'react';

export const LandscapeIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g id="layer1">
        <path
          fill="#5e72e4"
          d="M 0 2 L 0 18 L 20 18 L 20 2 L 0 2 z M 1 3 L 19 3 L 19 17 L 1 17 L 1 3 z M 15.5 4 A 2.5 2.5 0 0 0 13 6.5 A 2.5 2.5 0 0 0 15.5 9 A 2.5 2.5 0 0 0 18 6.5 A 2.5 2.5 0 0 0 15.5 4 z M 15.5 5 A 1.5 1.5 0 0 1 17 6.5 A 1.5 1.5 0 0 1 15.5 8 A 1.5 1.5 0 0 1 14 6.5 A 1.5 1.5 0 0 1 15.5 5 z M 5 9 C 3.9822714 9.0007386 2.9706189 9.1568334 2 9.4628906 L 2 10.525391 C 2.9628904 10.1811 3.977414 10.003427 5 10 C 6.8633463 10.0025 8.6799933 10.583212 10.199219 11.662109 C 10.51172 11.468361 10.833739 11.290403 11.164062 11.128906 C 9.4049899 9.7504194 7.2348516 9.0009103 5 9 z M 16 11 C 12.432963 11.0056 9.1389853 12.910849 7.3554688 16 L 8.5214844 16 C 10.190484 13.502069 12.9958 12.001602 16 12 C 16.673335 12.0031 17.3442 12.08168 18 12.234375 L 18 11.212891 C 17.342182 11.074894 16.672127 11.00357 16 11 z"
        />
      </g>
    </svg>
  );
};
