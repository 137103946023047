import React, { ForwardedRef, useCallback } from 'react';
import { Box } from '../components/Box';
import { ImageUpload } from '../components/ImageUpload';
import { BottomActions } from '../components/BottomActions';
import { NewsFeedTitleAndDescription } from './NewsFeedTitleAndDescription';
import { NewsFeedDescription } from './NewsFeedDescription';
import { useFacebook } from '../../../context';
import { NewsFeedAdFormat } from '../../../types';
import { Carousel } from '../Carousel';

export const NewsFeed = React.forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { newsFeedFormat } = useFacebook();

  const carouselHandler = useCallback(() => {
    return newsFeedFormat === NewsFeedAdFormat.SingleImage ? (
      <>
        <ImageUpload />
        <NewsFeedDescription />
      </>
    ) : (
      <Carousel />
    );
  }, [newsFeedFormat]);

  return (
    <>
      <Box ref={ref}>
        <NewsFeedTitleAndDescription />
        {carouselHandler()}
        <BottomActions />
      </Box>
    </>
  );
});

NewsFeed.displayName = 'NewsFeed';
