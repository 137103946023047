import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useFacebook } from '../../../context';
import { Dropdown } from '../../dropdown';

export const StorySelect = () => {
  const [visibleSelect, setVisibleSelect] = useState<boolean>(true);
  const [visibleDropdown, setIsVisibleDropdown] = useState<boolean>(false);

  const { storyItem, isHoveredBox } = useFacebook();
  const { storySelectValue } = storyItem;

  useEffect(() => {
    switch (storySelectValue.id) {
      case 0:
        if (isHoveredBox && storySelectValue.id === 0) {
          setVisibleSelect(true);
        } else {
          setVisibleSelect(false);
        }
        break;
      default:
        setVisibleSelect(true);
    }
  }, [storySelectValue.id, isHoveredBox]);
  return visibleSelect ? (
    <Wrapper>
      <Dropdown
        value={storySelectValue}
        visible={visibleDropdown}
        setVisible={setIsVisibleDropdown}
      />
      <Trigger onClick={() => setIsVisibleDropdown(!visibleDropdown)}>
        {storySelectValue.label}
      </Trigger>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div(
  ({ theme }) => `
    position: relative;
    background-color: ${theme.colors.grayButton};
    padding: ${theme.spacing.spacing6} ${theme.spacing.spacing12};
    border-radius: ${theme.borderRadius.mega};
    height: 25px;
    text-align: center;
    display: flex;
    text-wrap: nowrap;
    cursor: pointer;
    justify-content: center;

  `
);
const Trigger = styled.div(
  ({ theme }) => `
  font-weight: ${theme.fontWeights.bold};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.spacing10} ${theme.spacing.spacing14};
  `
);
