import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { useTheme } from '../../../hooks';

interface ProfilePhotoInterface {
  placeholder: string | undefined;
  selectedImage: Blob | MediaSource | undefined;
  width?: number;
  height?: number;
}

export const ProfilePhoto: React.FC<ProfilePhotoInterface> = ({
  placeholder,
  selectedImage,
  width,
  height,
}) => {
  const theme = useTheme();
  const getActiveStyle = useCallback(() => {
    return {
      style: {
        width: width ? `${width}px` : '50px',
        height: height ? `${height}px` : '50px',
        background: width && height ? theme.colors.white : 'transparent',
      },
    };
  }, [width, height]);
  return (
    <StyledImage
      {...getActiveStyle()}
      src={selectedImage === undefined ? placeholder : URL.createObjectURL(selectedImage!)}
      alt="Facebook mockup"
    />
  );
};

const StyledImage = styled.img(
  () => `
    object-fit: cover;
    border-radius: 50px
  `
);
