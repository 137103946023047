import React, {
  useState,
  createContext,
  useContext,
  FormEvent,
  ChangeEvent,
  useEffect,
} from 'react';
import {
  DeviceType,
  FacebookAdType,
  FacebookCardTextValues,
  FacebookContentChangeHandler,
  FacebookImages,
  NewsFeedAdFormat,
  NewsFeedCarouselItem,
  NewsFeedItem,
  SelectFormat,
  StoryAdFormat,
  StoryItem,
} from '../types';
import { useResponsive } from 'hooks';
import Compressor from 'compressorjs';

const defaultSelectValues = { id: 6, label: 'Download' };

type FacebookContentType = {
  newsFeedItem: NewsFeedItem;
  deviceType: string;
  carouselData: NewsFeedCarouselItem[];
  setDeviceType: (arg: string) => void;
  adType: string;
  setAdType: (arg: string) => void;
  storyItem: StoryItem;
  storyFormat: string;
  editCarouselItem: <T extends keyof NewsFeedCarouselItem>(
    id: number,
    field: T,
    value: NewsFeedCarouselItem[T]
  ) => void;
  setStoryFormat: (arg: string) => void;
  newsFeedFormat: string;
  setNewsFeedFormat: (arg: string) => void;
  handleContentEditableChange: (
    event: FormEvent<HTMLSpanElement>,
    type: FacebookContentChangeHandler,
    id?: number
  ) => void;
  isHoveredBox: boolean;
  setIsHoveredBox: (arg: boolean) => void;
  handleCompressedUpload: (
    e: ChangeEvent<HTMLInputElement> | undefined,
    type: string,
    id?: number
  ) => void;
  facebookSelectHandler: (id: number, name: string) => void;
  facebookSelectValue: () => { id: number; label: string };
  addCarouselItem: (image: File) => void;
  editPlaceholderNewsFeedItem: <T extends keyof NewsFeedCarouselItem>(
    field: T,
    value: NewsFeedCarouselItem[T]
  ) => void;
  placeholderData: NewsFeedCarouselItem;
  deleteCarouselItem: () => void;
  setSelectFormat: (arg: string) => void;
  selectValueHandler: (
    id: number,
    label: string,
    carouselItemId?: number,
    placeholder?: boolean
  ) => void;
  showAllSlides: boolean;
  setShowAllSlides: (arg: boolean) => void;
  isHoveredSlider: boolean;
  setIsHoveredSlider: (arg: boolean) => void;
  itemToDelete: NewsFeedCarouselItem | null;
  setItemToDelete: (arg: NewsFeedCarouselItem) => void;
  activeMobileSidebar: boolean;
  setActiveMobileSidebar: (arg: boolean) => void;
  loading: boolean;
  loadingCarouselItemId: number | null;
};

export const FacebookContext = createContext<FacebookContentType | undefined>(undefined);

export const FacebookProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [carouselData, setCarouselData] = useState<NewsFeedCarouselItem[]>([]);
  const [newsFeedItem, setNewsFeedItem] = useState<NewsFeedItem>({
    id: 0,
    newsFeedMainImage: undefined,
    newsFeedProfileImage: undefined,
    newsFeedSelectValue: defaultSelectValues,
    newsFeedAddressTitle: FacebookCardTextValues.NewsFeedAddressTitle,
    newsFeedMainDescription: FacebookCardTextValues.NewsFeedMainDescription,
    newsFeedDescriptionTitle: FacebookCardTextValues.NewsFeedDescriptionTitle,
    newsFeedDescriptionSubtitle: FacebookCardTextValues.NewsFeedDescriptionSubtitle,
    newsFeedDescription: FacebookCardTextValues.NewsFeedDescription,
  });
  const [placeholderData, setPlaceholderData] = useState<NewsFeedCarouselItem>({
    id: carouselData.length + 1,
    newsFeedMainImage: undefined,
    newsFeedSelectValue: defaultSelectValues,
    newsFeedDescriptionSubtitle: FacebookCardTextValues.CarouselHeadlinePlaceholder,
    newsFeedDescription: FacebookCardTextValues.CarouselDescriptionPlaceholder,
  });
  const [newsFeedFormat, setNewsFeedFormat] = useState<string>(NewsFeedAdFormat.SingleImage);
  const [storyItem, setStoryItem] = useState<StoryItem>({
    storyMainImage: undefined,
    storyProfileImage: undefined,
    storyAdreesTitle: FacebookCardTextValues.StoryAddressTitle,
    storyDescription: FacebookCardTextValues.StoryDescription,
    storySelectValue: defaultSelectValues,
  });
  const [deviceType, setDeviceType] = useState<string>(DeviceType.Desktop);
  const [adType, setAdType] = useState<string>(FacebookAdType.NewsFeed);
  const [selectFormat, setSelectFormat] = useState<string>(SelectFormat.NewsFeed);
  const [storyFormat, setStoryFormat] = useState<string>(StoryAdFormat.Original);
  const [isHoveredBox, setIsHoveredBox] = useState<boolean>(false);
  const [showAllSlides, setShowAllSlides] = useState<boolean>(false);
  const [isHoveredSlider, setIsHoveredSlider] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<NewsFeedCarouselItem | null>(null);
  const [activeMobileSidebar, setActiveMobileSidebar] = useState<boolean>(false);
  const { isRendered } = useResponsive(991);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCarouselItemId, setLoadingCarouselItemId] = useState<number | null>(null);

  const editNewsFeedItemAndUpdateFirstItemInCarousel = <T extends keyof NewsFeedCarouselItem>(
    field: T,
    value: NewsFeedCarouselItem[T],
    id?: number
  ) => {
    if (typeof id !== 'undefined') {
      editCarouselItem(id, field, value);
    }
  };

  const handleContentEditableChange = (
    event: FormEvent<HTMLSpanElement>,
    type: FacebookContentChangeHandler,
    id?: number
  ) => {
    const newTextContent = (event.target as HTMLDivElement).innerHTML || '';
    switch (type) {
      case FacebookContentChangeHandler.NewsFeedAddressTitle:
        setNewsFeedItem({ ...newsFeedItem, newsFeedAddressTitle: newTextContent });
        break;
      case FacebookContentChangeHandler.NewsFeedMainDescription:
        setNewsFeedItem({ ...newsFeedItem, newsFeedMainDescription: newTextContent });
        break;
      case FacebookContentChangeHandler.NewsFeedDescriptionTitle:
        setNewsFeedItem({ ...newsFeedItem, newsFeedDescriptionTitle: newTextContent });
        break;
      case FacebookContentChangeHandler.NewsFeedDescriptionSubtitle:
        setNewsFeedItem({ ...newsFeedItem, newsFeedDescriptionSubtitle: newTextContent });
        editNewsFeedItemAndUpdateFirstItemInCarousel(
          FacebookContentChangeHandler.NewsFeedDescriptionSubtitle,
          newTextContent,
          id
        );
        break;
      case FacebookContentChangeHandler.NewsFeedDescription:
        setNewsFeedItem({ ...newsFeedItem, newsFeedDescription: newTextContent });
        editNewsFeedItemAndUpdateFirstItemInCarousel(
          FacebookContentChangeHandler.NewsFeedDescription,
          newTextContent,
          id
        );
        break;
      case FacebookContentChangeHandler.StoryAddressTitle:
        setStoryItem({ ...storyItem, storyAdreesTitle: newTextContent });
        break;
      case FacebookContentChangeHandler.StoryDescription:
        setStoryItem({ ...storyItem, storyDescription: newTextContent });
        break;
      default:
        break;
    }
  };

  const editCarouselItem = <T extends keyof NewsFeedCarouselItem>(
    id: number,
    field: T,
    value: NewsFeedCarouselItem[T]
  ) => {
    const index = carouselData.findIndex((item) => item.id === id);
    if (index !== -1) {
      const updatedObject = { ...carouselData[index], [field]: value };
      const newData = [...carouselData];
      newData[index] = updatedObject;
      setCarouselData(newData);
    }
    if (id === newsFeedItem.id) {
      setNewsFeedItem({ ...newsFeedItem, [field]: value });
    }
  };

  const handleCompressedUpload = (
    e: ChangeEvent<HTMLInputElement> | undefined,
    type?: string,
    id?: number
  ) => {
    setLoading(true);
    if (e && e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      return new Compressor(image, {
        quality: 0.8,
        success: (compressedResult) => {
          switch (type) {
            case FacebookImages.NewsFeedMainImage:
              setNewsFeedItem({ ...newsFeedItem, newsFeedMainImage: compressedResult });
              if (typeof id !== 'undefined') {
                editCarouselItem(id, FacebookImages.NewsFeedMainImage, compressedResult);
              }
              break;
            case FacebookImages.CarouselImage:
              if (typeof id !== 'undefined') {
                setLoadingCarouselItemId(id!);
                editCarouselItem(id, FacebookImages.NewsFeedMainImage, compressedResult);
              }
              if (typeof id !== 'undefined' && id === newsFeedItem.id) {
                setNewsFeedItem({ ...newsFeedItem, newsFeedMainImage: compressedResult });
              }
              setLoadingCarouselItemId(null);
              break;
            case FacebookImages.NewsFeedProfileImage:
              setNewsFeedItem({ ...newsFeedItem, newsFeedProfileImage: compressedResult });
              break;
            case FacebookImages.StoryMainImage:
              setStoryItem({ ...storyItem, storyMainImage: compressedResult });
              break;
            case FacebookImages.StoryProfileImage:
              setStoryItem({ ...storyItem, storyProfileImage: compressedResult });
              break;
            default:
              break;
          }
          setLoading(false);
        },
      });
    }
  };

  const facebookSelectHandler = (id: number, label: string, carouselId?: number) => {
    switch (adType) {
      case FacebookAdType.NewsFeed:
        setNewsFeedItem({ ...newsFeedItem, newsFeedSelectValue: { id, label } });
        break;
      case FacebookAdType.Story:
        setStoryItem({ ...storyItem, storySelectValue: { id, label } });
        break;
      default:
        setNewsFeedItem({ ...newsFeedItem, newsFeedSelectValue: { id, label } });
    }
  };

  const facebookSelectValue = () => {
    switch (selectFormat) {
      case SelectFormat.NewsFeed:
        return newsFeedItem.newsFeedSelectValue;
      case SelectFormat.Story:
        return storyItem.storySelectValue;
      default:
        return newsFeedItem.newsFeedSelectValue;
    }
  };

  const selectValueHandler = (
    id: number,
    label: string,
    carouselItemId?: number,
    placeholder?: boolean
  ) => {
    switch (selectFormat) {
      case SelectFormat.NewsFeed:
        setNewsFeedItem({ ...newsFeedItem, newsFeedSelectValue: { id, label } });
        if (newsFeedItem.id === carouselData[0].id) {
          editCarouselItem(carouselData[0].id, FacebookContentChangeHandler.NewsFeedSelectValue, {
            id,
            label,
          });
        }
        break;
      case SelectFormat.NewsFeedCarousel:
        if (carouselItemId !== undefined) {
          editCarouselItem(carouselItemId, FacebookContentChangeHandler.NewsFeedSelectValue, {
            id,
            label,
          });
        }
        if (placeholder) {
          setPlaceholderData({ ...placeholderData, newsFeedSelectValue: { id, label } });
        }
        break;
      case SelectFormat.Story:
        setStoryItem({ ...storyItem, storySelectValue: { id, label } });
        break;
      default:
        setNewsFeedItem({ ...newsFeedItem, newsFeedSelectValue: { id, label } });
    }
  };

  const showCarouselHandler = () => {
    if (!carouselData.length) {
      const carouselData: NewsFeedCarouselItem[] = [
        {
          id: newsFeedItem.id,
          newsFeedMainImage: newsFeedItem.newsFeedMainImage,
          newsFeedSelectValue: newsFeedItem.newsFeedSelectValue,
          newsFeedDescriptionSubtitle: newsFeedItem.newsFeedDescriptionSubtitle,
          newsFeedDescription: newsFeedItem.newsFeedDescription,
        },
      ];
      for (let i = 1; i < 2; i++) {
        carouselData.push({
          id: i,
          newsFeedMainImage: undefined,
          newsFeedSelectValue: defaultSelectValues,
          newsFeedDescriptionSubtitle: FacebookCardTextValues.NewsFeedDescriptionSubtitle,
          newsFeedDescription: FacebookCardTextValues.NewsFeedDescription,
        });
      }
      setCarouselData(carouselData);
    }
  };

  useEffect(() => showCarouselHandler(), []);

  const resetPlaceholder = () => {
    setPlaceholderData({
      id: carouselData.length + 1,
      newsFeedMainImage: undefined,
      newsFeedSelectValue: defaultSelectValues,
      newsFeedDescriptionSubtitle: FacebookCardTextValues.CarouselHeadlinePlaceholder,
      newsFeedDescription: FacebookCardTextValues.CarouselDescriptionPlaceholder,
    });
  };

  const addCarouselItem = (image: File) => {
    if (image) {
      setCarouselData([
        ...carouselData,
        {
          ...placeholderData,
          id: carouselData[carouselData.length - 1].id + 1,
          newsFeedMainImage: image,
        },
      ]);
      resetPlaceholder();
    }
  };

  const editPlaceholderNewsFeedItem = <T extends keyof NewsFeedCarouselItem>(
    field: T,
    value: NewsFeedCarouselItem[T]
  ) => {
    setPlaceholderData({ ...placeholderData, [field]: value });
  };

  const deleteCarouselItem = () => {
    const updatedItems = carouselData.filter((item) => item.id !== itemToDelete!.id);
    setCarouselData(updatedItems);
    if (itemToDelete!.id === newsFeedItem.id) {
      setNewsFeedItem({
        id: updatedItems[0].id,
        newsFeedMainImage: updatedItems[0].newsFeedMainImage,
        newsFeedProfileImage: newsFeedItem.newsFeedProfileImage,
        newsFeedSelectValue: newsFeedItem.newsFeedSelectValue,
        newsFeedAddressTitle: newsFeedItem.newsFeedAddressTitle,
        newsFeedMainDescription: newsFeedItem.newsFeedMainDescription,
        newsFeedDescriptionTitle: newsFeedItem.newsFeedDescriptionTitle,
        newsFeedDescriptionSubtitle: updatedItems[0].newsFeedDescriptionSubtitle,
        newsFeedDescription: updatedItems[0].newsFeedDescription,
      });
    }
  };

  useEffect(() => {
    if (isRendered) {
      setDeviceType(DeviceType.Mobile);
    } else {
      setDeviceType(DeviceType.Desktop);
    }
  }, [isRendered]);

  return (
    <FacebookContext.Provider
      value={{
        newsFeedItem,
        storyItem,
        handleContentEditableChange,
        handleCompressedUpload,
        deviceType,
        setDeviceType,
        isHoveredBox,
        setIsHoveredBox,
        adType,
        setAdType,
        storyFormat,
        setStoryFormat,
        newsFeedFormat,
        setNewsFeedFormat,
        facebookSelectHandler,
        facebookSelectValue,
        carouselData,
        editCarouselItem,
        editPlaceholderNewsFeedItem,
        addCarouselItem,
        placeholderData,
        deleteCarouselItem,
        setSelectFormat,
        selectValueHandler,
        showAllSlides,
        setShowAllSlides,
        isHoveredSlider,
        setIsHoveredSlider,
        itemToDelete,
        setItemToDelete,
        activeMobileSidebar,
        setActiveMobileSidebar,
        loading,
        loadingCarouselItemId,
      }}>
      {children}
    </FacebookContext.Provider>
  );
};

export const useFacebook = () => {
  const context = useContext(FacebookContext);

  if (!context) {
    throw new Error('useFacebook must be used within a FacebookProvider');
  }

  return context;
};
