import React from 'react';
import { styled } from 'styled-components';

type IconLinkProps = {
  href?: string;
  newTab?: boolean;
  onClick?: () => void;
} & React.PropsWithChildren;

export const IconLink: React.FC<IconLinkProps> = ({ href, newTab = false, onClick, children }) => {
  return (
    <Wrapper onClick={onClick} href={href} target={newTab ? '_blank' : '_self'}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.a`
  cursor: pointer;
`;
