import React from 'react';
import { styled } from 'styled-components';
import { CommentIcon, LikeIcon, ShareIcon } from '../../../assets';

export const BottomActions = () => {
  return (
    <Wrapper>
      <IconContainer>
        <LikeIcon />
        <Like>Like</Like>
      </IconContainer>
      <IconContainer>
        <CommentIcon />
        <Text>Comment</Text>
      </IconContainer>
      <IconContainer>
        <ShareIcon />
        <Text>Share</Text>
      </IconContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing.spacing6} ${theme.spacing.spacing14};
    display: flex;
    padding: ${theme.spacing.spacing8} ${theme.spacing.spacing20};
`
);

const IconContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.spacing5};
  flex: 1 100%;
  height: 32px;
  padding: ${theme.spacing.spacing0} ${theme.spacing.spacing2};
  cursor: pointer;
`
);

const Text = styled.span(
  ({ theme }) => `
  color: ${theme.colors.grayHigh};
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.semibold}
`
);
const Like = styled(Text)(
  ({ theme }) => `
  margin-top: ${theme.spacing.spacing3}
`
);
