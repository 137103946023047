import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

type NavigationBoxProps = {
  icon: React.ReactNode;
  link: string;
  text: string;
};

export const NavigationBox: FC<NavigationBoxProps> = ({ icon, link, text }) => {
  const navigation = useNavigate();

  const clickHandler = () => {
    navigation(link);
  };

  return (
    <Container onClick={clickHandler}>
      {icon}
      <p style={{ color: '#fff' }}>{text}</p>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => `
    width: 150px;
    height: 150px;
    background-color: ${theme.colors.primary};
    border-radius: ${theme.borderRadius.big};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing.spacing15};
    transition: all .3s linear;
    cursor: pointer;
    &:hover {
        transform: scale(1.2)
    }
`
);
