import React from 'react';
import { styled } from 'styled-components';
import { Trigger } from '../../../Trigger';
import { DotsIcon, PenIcon } from '../../../../assets';
import { NewsFeedMainContent } from './NewsFeedMainContent';
import { NewsFeedMainDescription } from './NewsFeedMainDescription';
import { useFacebook } from 'context';

export const NewsFeedTitleAndDescription = () => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { isHoveredBox } = useFacebook();
  return (
    <Wrapper>
      <StyledDots>
        <DotsIcon />
      </StyledDots>
      {isHoveredBox && (
        <BackdropContaier>
          <Trigger onClick={() => fileInputRef.current?.click()} icon={<PenIcon />} />
        </BackdropContaier>
      )}
      <NewsFeedMainContent ref={fileInputRef} />
      <NewsFeedMainDescription />
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing.spacing12} ${theme.spacing.spacing16};
    position: relative;
`
);

const BackdropContaier = styled.div(
  () => `
  width: 49px;
  height: 49px;
  top: 15px;
  left: 19px;
  position: absolute;
  border-radius: 25px;
  overflow: hidden;
`
);

const StyledDots = styled.div(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing.spacing16};
  top: ${theme.spacing.spacing16};
`
);
