import React, { ChangeEvent, ForwardedRef } from 'react';
import { styled } from 'styled-components';
interface InputProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
export const FileInput = React.forwardRef(
  ({ onChange }: InputProps, ref: ForwardedRef<HTMLInputElement>) => (
    <StyledInput accept="image/*" type="file" ref={ref} onChange={onChange} />
  )
);

const StyledInput = styled.input`
  display: none;
`;

FileInput.displayName = 'FileInput';
