import React from 'react';

export const FooterLogo: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="45px"
      height="50px"
      viewBox="0 0 45 50">
      <g>
        <g>
          <g>
            <path
              fill="#5E72E4"
              d="M0,16.819c0-2.785,1.936-6.202,4.301-7.594l13.897-8.181c2.365-1.392,6.236-1.392,8.602,0l13.898,8.181
				C43.064,10.617,45,14.034,45,16.819V33.18c0,2.785-1.936,6.203-4.301,7.596L26.8,48.957c-2.365,1.391-6.236,1.391-8.602,0
				L4.301,40.775C1.936,39.383,0,35.965,0,33.18V16.819z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#161F32"
            d="M20.885,10.097l-4.917,2.939l-4.456,2.66c-1.287,0.77-2.339,2.642-2.339,4.161v11.015
			c0,2.785,1.929,3.912,4.286,2.506c0,0,0.001,0,0.002,0l7.424-4.438h0.002l1.947-1.164c1.286-0.768,2.338-2.64,2.338-4.16V12.602
			C25.172,9.816,23.244,8.689,20.885,10.097z"
          />
          <path
            fill="#FB5D59"
            d="M26.212,13.207l-4.918,2.938l-4.456,2.662c-1.287,0.767-2.338,2.64-2.338,4.16v11.015
			c0,2.785,1.928,3.912,4.285,2.506c0.001,0,0.003-0.002,0.003-0.002l7.424-4.434l0.002-0.002l1.946-1.164
			c1.286-0.77,2.339-2.641,2.339-4.16V15.711C30.5,12.925,28.571,11.796,26.212,13.207z"
          />
          <path
            fill="#FFFFFF"
            d="M31.539,16.623l-4.917,2.938l-4.456,2.662c-1.287,0.767-2.338,2.64-2.338,4.16v11.016
			c0,2.785,1.928,3.912,4.285,2.506c0-0.002,0.002-0.002,0.002-0.002l7.424-4.436c0.001,0,0.002,0,0.002,0l1.947-1.164
			c1.286-0.768,2.339-2.641,2.339-4.16V19.126C35.827,16.341,33.898,15.213,31.539,16.623z"
          />
        </g>
      </g>
    </svg>
  );
};
