import React, { useRef } from 'react';
import { FacebookOptions, Grid, FacebookGenerator } from '../components';
import { AdSense } from '../components/AdSense';
import styled from 'styled-components';

export const Facebook = () => {
  const exportRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Title>Free Online Facebook post generator</Title>
      <AdSense client="ca-pub-0147907188440311" slot="2444738509"></AdSense>
      <Grid
        leftContent={<FacebookGenerator ref={exportRef} />}
        rightContent={<FacebookOptions ref={exportRef} />}
      />
    </>
  );
};

const Container = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing.spacing32};
    padding: ${theme.spacing.spacing0} ${theme.spacing.spacing32}
`
);

const Title = styled.h1(
  () => `
    text-align: center;
`
);
