import React, { useCallback } from 'react';
import { useFacebook } from 'context';
import { DeviceType, FacebookContentChangeHandler } from '../../../types';
import styled from 'styled-components';
import { useTheme } from 'hooks';

interface CarouselSubtitleInterface {
  id: number;
  newsFeedDescriptionSubtitle: string;
  preview?: boolean;
}

export const CarouselSubtitle: React.FC<CarouselSubtitleInterface> = ({
  id,
  newsFeedDescriptionSubtitle,
  preview = false,
}) => {
  const theme = useTheme();
  const { editCarouselItem, isHoveredBox, deviceType } = useFacebook();

  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor:
          isHoveredBox && !preview ? `${theme.colors.gray}` : `${theme.colors.transparent}`,
        fontSize: deviceType === DeviceType.Desktop ? theme.fontSizes.big : theme.fontSizes.body,
      },
    };
  }, [isHoveredBox, preview, deviceType]);
  return (
    <Subtitle
      {...getStyle()}
      contentEditable={!preview}
      suppressContentEditableWarning
      onBlur={(event) =>
        editCarouselItem(
          id,
          FacebookContentChangeHandler.NewsFeedDescriptionSubtitle,
          (event.target as HTMLDivElement).innerHTML
        )
      }>
      {newsFeedDescriptionSubtitle}
    </Subtitle>
  );
};

const Subtitle = styled.span(
  ({ theme }) => `
    color: ${theme.colors.blackMiddle};
    font-weight: ${theme.fontWeights.semibold};
    overflow: hidden;
    outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
    margin-bottom: ${theme.spacing.spacing3};
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    max-height: calc(${theme.spacing.spacing32} * 2);
    line-height: 1.2em;
    display: -webkit-box; 
    -webkit-line-clamp: 2;
  `
);
