export const theme = {
  colors: {
    primary: '#161f32',
    secondary: '#5e72e4',
    thernary: '#243556',
    tertiray: ' #4959b7',
    gray: '#454c5b',
    grayDark: '#6a6d89',
    grayLight: '#dddfe2',
    grayMid: '#f0f2f5',
    grayNeutral: '#65676b',
    grayHigh: '#616770',
    black: '#000',
    white: '#ffffff',
    facebook: '#1877f2',
    backdrop: ' #00000033',
    grayButton: '#E4E6EB',
    transparent: 'transparent',
    blueNeutral: '#1c1e21',
    blackMiddle: '#050505',
  },
  spacing: {
    negative1: '-1px',
    negative3: '-3px',
    spacing0: '0px',
    spacing1: '1px',
    spacing2: '2px',
    spacing3: '3px',
    spacing4: '4px',
    spacing5: '5px',
    spacing6: '6px',
    spacing8: '8px',
    spacing10: '10px',
    spacing12: '12px',
    spacing14: '14px',
    spacing15: '15px',
    spacing16: '16px',
    spacing18: '18px',
    spacing20: '20px',
    spacing25: '25px',
    spacing32: '32px',
    spacing40: '40px',
    spacing50: '50px',
  },
  fontSizes: {
    giant: '72px',
    giga: '32px',
    ultra: '18px',
    mega: '17px',
    extra: '16px',
    big: '15px',
    body: '14px',
    small: '13px',
    default: '12px',
    nano: '11px',
  },
  fontWeights: {
    normal: '400',
    semibold: '600',
    bold: '700',
  },
  borderWidth: {
    default: '2px',
    small: '1px',
  },
  borderStyle: {
    solid: 'solid',
    dashed: 'dashed',
  },
  borderRadius: {
    mega: '48px',
    large: '20px',
    big: '10px',
    default: '5px',
    small: '6px',
    micro: '3px',
    half: '50%',
  },
};
