import React, { ForwardedRef, useCallback } from 'react';
import { styled } from 'styled-components';
import { useFacebook } from '../../../context';
import { DeviceType } from '../../../types';

export const Box = React.forwardRef(
  ({ children }: React.PropsWithChildren, ref: ForwardedRef<HTMLDivElement>) => {
    const { deviceType, setIsHoveredBox } = useFacebook();

    const getActiveStyle = useCallback(() => {
      return {
        style: {
          maxWidth: deviceType === DeviceType.Desktop ? '500px' : '375px',
        },
      };
    }, [deviceType]);

    return (
      <StyledBox
        {...getActiveStyle()}
        ref={ref}
        onMouseEnter={() => setIsHoveredBox(true)}
        onMouseLeave={() => setIsHoveredBox(false)}>
        {children}
      </StyledBox>
    );
  }
);

const StyledBox = styled.div(
  ({ theme }) => `
    border: ${theme.borderWidth.small} ${theme.borderStyle.solid} ${theme.colors.grayLight};
    border-radius: 4px;
    margin: 0 auto;
    background-color: ${theme.colors.white};
`
);

Box.displayName = 'Box';
