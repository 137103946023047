import React from 'react';
import { styled } from 'styled-components';

export const Divider = () => {
  return <Wrapper />;
};
const Wrapper = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 1px;
    margin: ${theme.spacing.spacing18} 0;
    border-bottom: ${theme.borderWidth.small} ${theme.borderStyle.solid} ${theme.colors.secondary}
`
);
