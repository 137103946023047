import React from 'react';

export const BackToTopIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      fill="#fff"
      width="24"
      height="24"
      version="1.1"
      id="arrow-up"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      data-v-32b2cfb0="">
      <g>
        <path
          d="M505.752,358.248L271.085,123.582c-8.331-8.331-21.839-8.331-30.17,0L6.248,358.248c-8.331,8.331-8.331,21.839,0,30.17
			s21.839,8.331,30.17,0L256,168.837l219.582,219.582c8.331,8.331,21.839,8.331,30.17,0S514.083,366.58,505.752,358.248z"></path>
      </g>
    </svg>
  );
};
