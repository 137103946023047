import React from 'react';
import { useFacebook } from '../../../context';
import { FacebookAdType, NewsFeedAdFormat } from '../../../types';
import { CarouselFormat } from './CarouselFormat';
import { Divider } from 'components';

export const CarouselFormatWrapper = () => {
  const { newsFeedFormat, adType } = useFacebook();
  switch (newsFeedFormat) {
    case NewsFeedAdFormat.Carousel:
      return adType === FacebookAdType.NewsFeed ? (
        <>
          <CarouselFormat />
          <Divider />
        </>
      ) : null;
    case NewsFeedAdFormat.SingleImage:
      return null;
    default:
      return <CarouselFormat />;
  }
};
