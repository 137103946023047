import React, { useCallback } from 'react';
import { styled } from 'styled-components';

interface ProgressBarInterface {
  percentage: number;
}

export const ProgressBar: React.FC<ProgressBarInterface> = ({ percentage = 0 }) => {
  const getStyle = useCallback(() => {
    return {
      style: {
        width: `${percentage}%`,
      },
    };
  }, [percentage]);

  return (
    <Wrapper>
      <Progress {...getStyle()} />
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.gray};
`
);

const Progress = styled.div(
  ({ theme }) => `
    height: 100%;
    background-color: ${theme.colors.grayMid};
    border-radius: 4px;
    transition: width 0.3s ease-in-out;
`
);
