import React from 'react';
import { styled } from 'styled-components';
import { StyledFlexRow } from '../layout';
import { FooterLogo, BackToTopIcon } from '../../assets/svg';
import { IconLink } from '../IconLink';
import { FooterIcons } from './components/FooterIcons';
import { FooterLisItems } from './components/FooterListItems';

export const Footer = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <InnerContainer>
          <FooterLisItems />
          <IconLink>
            <FooterLogo />
          </IconLink>
          <IconsContainer>
            <White>Follow Us:</White>
            <FooterIcons />
          </IconsContainer>
        </InnerContainer>
        <Border />
        <InnerContainerBottom>
          <ReservedText>
            ©2019 - 2022 <ReservedTextSpan>MockupBro</ReservedTextSpan>, All Right Reserved
          </ReservedText>
          <IconLink newTab={false}>
            <StyledBackToTopIcon>
              <BackToTopIcon />
            </StyledBackToTopIcon>
          </IconLink>
        </InnerContainerBottom>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.footer(
  ({ theme }) => `
    background-color: ${theme.colors.primary};
    padding: ${theme.spacing.spacing40} 0;
`
);
const InnerWrapper = styled.div(
  () => `
  max-width: 1140px;
  margin: 0 auto;
`
);
const InnerContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${theme.spacing.spacing10} ${theme.spacing.spacing15};
    align-items: center;
    @media(max-width: 991px) {
      flex-direction: column;
      gap: ${theme.spacing.spacing12}
    }
`
);

const InnerContainerBottom = styled(InnerContainer)(
  ({ theme }) => `
    align-items: flex-start;
    padding: 0 ${theme.spacing.spacing15}
`
);

const Border = styled.div(
  ({ theme }) => `
    border-bottom: ${theme.borderWidth.small} solid ${theme.colors.gray};
    margin-bottom: ${theme.spacing.spacing15};
`
);

const White = styled.p(
  ({ theme }) => `
    color: ${theme.colors.white};
`
);

const IconsContainer = styled(StyledFlexRow)`
  gap: 14px;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: space-between;
  }
`;
const StyledBackToTopIcon = styled.div(
  ({ theme }) => `
  background: ${theme.colors.secondary};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #5e72e4;
  &:hover {
    background: transparent;
    border: 2px solid #5e72e4;
    position: relative;
  }
  @media(max-width: 991px) {
    position: absolute;
    bottom: -150px;
    right: 10px;
  }
`
);

const ReservedText = styled.p(
  ({ theme }) => `
    color: ${theme.colors.grayDark};
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeights.semibold};
`
);
const ReservedTextSpan = styled.span(
  ({ theme }) => `
    color: ${theme.colors.secondary};
`
);
