import React from 'react';

export const NewsFeedIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="40px" height="40px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="icons_Q2" fill="#5e72e4">
          <path d="M40,8V40H8V8H40m2-4H6A2,2,0,0,0,4,6V42a2,2,0,0,0,2,2H42a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Z" />
          <path d="M34,30H14a2,2,0,0,0,0,4H34a2,2,0,0,0,0-4Z" />
          <path d="M34,22H28a2,2,0,0,0,0,4h6a2,2,0,0,0,0-4Z" />
          <path d="M34,14H28a2,2,0,0,0,0,4h6a2,2,0,0,0,0-4Z" />
          <rect x="12" y="14" width="10" height="12" rx="2" ry="2" />
        </g>
      </g>
    </svg>
  );
};
