import React from 'react';
import { styled } from 'styled-components';
import placeholder from '../../../assets/images/placeholder-image.png';
import { ImagePreview } from './ImagePreview';
import { FileInput } from '../../FileInput';
import { Backdrop } from '../../Backdrop';
import { Trigger } from '../../Trigger';
import { useFacebook } from '../../../context';
import { PenIcon } from '../../../assets';
import { FacebookImages } from '../../../types';

export const ImageUpload = () => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { newsFeedItem, handleCompressedUpload } = useFacebook();
  const { newsFeedMainImage } = newsFeedItem;
  return (
    <Wrapper>
      <Backdrop>
        <Trigger onClick={() => fileInputRef.current?.click()} icon={<PenIcon />} />
      </Backdrop>
      <FileInput
        ref={fileInputRef}
        onChange={(e) =>
          handleCompressedUpload(e, FacebookImages.NewsFeedMainImage, newsFeedItem.id)
        }
      />
      <ImagePreview placeholder={placeholder} selectedImage={newsFeedMainImage} />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: relative;
  display: flex;
`;
