import { useState, useEffect } from 'react';

export const useColorExtractor = (image?: File | Blob) => {
  const [primaryColor, setPrimaryColor] = useState<string | null>(null);
  const [hasTransparentBackground, setHasTransparentBackground] = useState<boolean>(false);

  const handleImageType = () => {
    if (image) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && typeof e.target.result === 'string') {
          const fileType = image.type;
          if (fileType === 'image/png' || fileType === 'image/gif') {
            setHasTransparentBackground(true);
          } else {
            setHasTransparentBackground(false);
          }
        }
      };

      reader.readAsDataURL(image);
    }
  };

  const extractPrimaryColor = (imageDataUrl: string) => {
    const canvas = document.createElement('canvas');
    canvas.width = 0;
    canvas.height = 0;
    setPrimaryColor(null);

    const img = document.createElement('img');
    img.src = imageDataUrl;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
        const pixelData = context.getImageData(0, 0, 1, 1).data;
        const rgbaColor = `rgba(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]}, ${
          pixelData[3] / 255
        })`;
        setPrimaryColor(rgbaColor);
      }
    };
  };

  useEffect(() => {
    if (image) {
      extractPrimaryColor(URL.createObjectURL(image));
      handleImageType();
    }
  }, [image]);

  return {
    primaryColor,
    hasTransparentBackground,
  };
};
