import React from 'react';
import { styled } from 'styled-components';

export const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div(
  ({ theme }) => `
    max-width: 1140px;
    width: 100%;
    padding: ${theme.spacing.spacing40} ${theme.spacing.spacing15};
    margin-right: auto;
    margin-left: auto; 
    min-height: 70vh;
    @media (max-width: 1190px) {
      padding: ${theme.spacing.spacing50} ${theme.spacing.spacing0};
    }
    `
);
