import React, { ForwardedRef, useEffect, useImperativeHandle, useState } from 'react';
import { styled } from 'styled-components';
import { BaseModalRef } from '../../types';

type ModalProps = {
  children: React.ReactNode;
};

export const BaseModal = React.forwardRef(
  ({ children }: ModalProps, ref: ForwardedRef<BaseModalRef>) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useImperativeHandle(
      ref,
      () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
      }),
      [isOpen]
    );

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      if (isOpen) {
        window.addEventListener('keydown', handleEscapeKey);
      } else {
        window.removeEventListener('keydown', handleEscapeKey);
      }

      return () => {
        window.removeEventListener('keydown', handleEscapeKey);
      };
    }, [isOpen]);

    if (!isOpen) return null;
    return (
      <div>
        <Backdrop>{children}</Backdrop>
      </div>
    );
  }
);

const Backdrop = styled.div(
  () => `
    background-color: rgba(0,0,0, 0.7);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`
);

BaseModal.displayName = 'BaseModal';
