import React from 'react';
import { styled } from 'styled-components';

type FooterLisItemProps = {
  href: string;
} & React.PropsWithChildren;

export const FooterLisItem: React.FC<FooterLisItemProps> = ({ children, href }) => {
  return <Wrapper href={href}>{children}</Wrapper>;
};

const Wrapper = styled.a(
  ({ theme }) => `
    color: ${theme.colors.white};
    &:hover {
        color: ${theme.colors.secondary};
    }
`
);
