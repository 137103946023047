import React, { useCallback, useEffect, useState } from 'react';
import { ProgressBar } from '../../ProgressBar';
import { styled } from 'styled-components';
import { ProfilePhoto } from '../components/ProfilePhoto';
import placeholder from '../../../assets/images/facebook-placeholder.png';
import { useFacebook } from '../../../context';
import { Backdrop } from '../../Backdrop';
import { Trigger } from '../../Trigger';
import { FileInput } from '../../FileInput';
import { CloseIcon, DotsIcon, PenIcon } from '../../../assets';
import { FacebookContentChangeHandler, FacebookImages } from '../../../types';
import { useTheme } from '../../../hooks';

export const StoryTopContent = () => {
  const [progress, setProgress] = useState<number>(0);
  const { storyItem, isHoveredBox, handleCompressedUpload, handleContentEditableChange } =
    useFacebook();
  const { storyProfileImage, storyAdreesTitle } = storyItem;
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor: isHoveredBox ? `${theme.colors.white}` : `${theme.colors.transparent}`,
      },
    };
  }, [isHoveredBox]);

  return (
    <Wrapper>
      <ProgressBar percentage={progress} />
      <DotsContainer>
        <DotsIcon color={theme.colors.white} />
      </DotsContainer>
      {isHoveredBox && (
        <CloseIconContainer>
          <CloseIcon />
        </CloseIconContainer>
      )}
      <InnerWrapper>
        <BackdropContaier>
          <Backdrop>
            <Trigger onClick={() => fileInputRef.current?.click()} icon={<PenIcon />} />
          </Backdrop>
        </BackdropContaier>
        <FileInput
          ref={fileInputRef}
          onChange={(e) => handleCompressedUpload(e, FacebookImages.StoryProfileImage)}
        />
        <ProfilePhoto
          width={32}
          height={32}
          placeholder={placeholder}
          selectedImage={storyProfileImage}
        />
        <TitleContainer>
          <Title
            {...getStyle()}
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) =>
              handleContentEditableChange(e, FacebookContentChangeHandler.StoryAddressTitle)
            }>
            {storyAdreesTitle}
          </Title>
          <Sponsored>Sponsored</Sponsored>
        </TitleContainer>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
    position: absolute;
    top: ${theme.spacing.spacing10};
    left: ${theme.spacing.spacing8};
    right: ${theme.spacing.spacing8};
    max-width: 100%;
    z-index: 20;
`
);

const InnerWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    margin-top: ${theme.spacing.spacing16};
    gap: ${theme.spacing.spacing3};
    align-items: center;
`
);
const BackdropContaier = styled.div(
  ({ theme }) => `
    width: ${theme.spacing.spacing32};
    height: ${theme.spacing.spacing32};
    position: absolute;
    border-radius: ${theme.borderRadius.mega};
    overflow: hidden;
    left: ${theme.spacing.spacing0};
    right: ${theme.spacing.spacing0};
  `
);

const TitleContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-left: ${theme.spacing.spacing3};
`
);

const DotsContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  z-index: 10;
  right: ${theme.spacing.spacing18};
  top: ${theme.spacing.spacing14};
`
);

const Sponsored = styled.p(
  ({ theme }) => `
    font-size: ${theme.fontSizes.nano};
    margin-top: ${theme.spacing.negative3};
    color: ${theme.colors.white};
`
);
const Title = styled.p(
  ({ theme }) => `
    font-size: ${theme.fontSizes.default};
    color: ${theme.colors.white};
    font-weight: ${theme.fontWeights.bold};
    overflow: hidden;
    outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
    width: fit-content;
    word-break: break-word;
`
);
const CloseIconContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  z-index: 20;
  right: ${theme.spacing.spacing5};
  top: ${theme.spacing.spacing40};
  cursor: pointer;
`
);
