import React from 'react';
import { styled } from 'styled-components';
import { MenuItem } from './MenuItem';
import { navigationMenu } from './data';

export const NavigationMenu = () => {
  return (
    <Container>
      <Wrapper>
        {navigationMenu.map((item) => (
          <MenuItem key={item.id} name={item.name} link={item.link} />
        ))}
      </Wrapper>
      {/* <Wrapper>
        {innerNavigation.map((item) => (
          <MenuItem key={item.id} name={item.name} link={item.link} inner />
        ))}
      </Wrapper> */}
    </Container>
  );
};
const Container = styled.div(
  () => `
display: flex;
flex-direction: row;
justify-content: space-between;
flex: 1;
`
);

const Wrapper = styled.ul(
  () => `
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
);
