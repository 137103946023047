import React from 'react';

export const DribbleLogo: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      fill="#F8F8F8"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="dribble-icon"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width="24"
      height="24"
      data-v-32b2cfb0="">
      <g>
        <path d="M175.872,197.8c24.598-4.801,48.6-11.4,71.398-18.6c-30.298-55.501-67.798-105.601-111.899-147.9   C70.572,66.099,21.27,128.2,5.371,202.299C53.072,211.899,111.972,210.7,175.872,197.8z"></path>{' '}
        <path d="M418.571,60.099l-4.2,16.5c-6.599,26.4-62.399,64.2-135.599,91.501l-3.301,1.199c-29.7-56.1-67.2-107.1-110.999-151.199   C192.672,7,223.571,0,255.671,0c57.299,0,110.7,20.2,153.3,52.299C412.27,54.699,415.572,57.1,418.571,60.099z"></path>{' '}
        <path d="M287.171,268.299c-7.8-21.299-16.5-41.999-26.1-62.1c-25.199,8.401-51.899,15.601-79.2,21   c-29.7,6-68.399,11.4-109.199,11.4c-23.401,0-48.401-1.8-72.1-6.301c-0.601,7.8,0.099,15.601,0.099,23.701   c0,78.9,36,149.399,92.1,196.199c0.3-1.8,0.601-3.6,0.899-5.4C105.071,377.8,184.872,304.9,287.171,268.299z"></path>{' '}
        <path d="M297.071,296.499c20.4,64.2,31.8,133.9,32.999,204.401c-23.399,7.2-48.6,11.1-74.399,11.1   c-48.3,0-93.6-14.5-132.001-37.901c-1.5-7.2-1.5-14.7-0.298-22.5C132.672,394.9,207.071,329.2,297.071,296.499z"></path>{' '}
        <path d="M508.97,292.299c-12.599,87.902-70.899,161.4-149.499,196.501c-2.1-69.6-14.101-137.401-34.2-201.301   C392.471,268.9,456.172,271,508.97,292.299z"></path>{' '}
        <path d="M511.671,256v5.4c-57.299-21.301-125.2-22.2-196-2.1c-7.8-21.599-16.8-42.599-26.4-62.999   c49.311-18.515,141.337-61.127,154.2-112.5C485.172,129.099,511.671,189.699,511.671,256z"></path>
      </g>
    </svg>
  );
};
