import React from 'react';
import { styled } from 'styled-components';
import { useFacebook } from '../../../context';
import { PenIcon } from '../../../assets';
import { FileInput } from '../../FileInput';
import { Backdrop } from '../../Backdrop';
import { Trigger } from '../../Trigger';
import { StoryImagePreview } from './StoryImagePreview';
import { FacebookImages } from '../../../types';

export const StoryImageUpload = () => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { storyItem, handleCompressedUpload } = useFacebook();
  const { storyMainImage } = storyItem;

  return (
    <Wrapper>
      <Backdrop borderRadius data-html2canvas-ignore>
        <Trigger onClick={() => fileInputRef.current?.click()} icon={<PenIcon />} />
      </Backdrop>
      <FileInput
        ref={fileInputRef}
        onChange={(e) => handleCompressedUpload(e, FacebookImages.StoryMainImage)}
      />
      <StoryImagePreview selectedImage={storyMainImage} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;
