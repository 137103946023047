import React, { useCallback } from 'react';
import { styled, useTheme } from 'styled-components';
import { useFacebook } from '../../../context';
import { FacebookContentChangeHandler } from 'types';

export const StoryMainDescription = () => {
  const { storyItem, isHoveredBox, handleContentEditableChange } = useFacebook();
  const { storyDescription } = storyItem;
  const theme = useTheme();

  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor: isHoveredBox ? `${theme.colors.white}` : `${theme.colors.transparent}`,
      },
    };
  }, [isHoveredBox]);

  return (
    <StyledText
      {...getStyle()}
      contentEditable
      suppressContentEditableWarning
      onBlur={(e) => handleContentEditableChange(e, FacebookContentChangeHandler.StoryDescription)}>
      {storyDescription}
    </StyledText>
  );
};

const StyledText = styled.p(
  ({ theme }) => `
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.extra};
    position: absolute;
    bottom: 20%;
    left: ${theme.spacing.spacing16};
    right: ${theme.spacing.spacing16};
    max-width: 100%;
    font-weight: ${theme.fontWeights.bold};
    z-index: 10;
    overflow: hidden;
    outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
    width: fit-content;
    word-break: break-word;
  `
);
