import React from 'react';
import { styled } from 'styled-components';
import { SettingsIcon } from 'assets';
import { ClickableIcon } from 'components/ClickableIcon';
import { useFacebook } from 'context';
import { useResponsive } from 'hooks';

interface GridInterface {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

export const Grid: React.FC<GridInterface> = ({ leftContent, rightContent }) => {
  const { activeMobileSidebar, setActiveMobileSidebar, setIsHoveredBox } = useFacebook();
  const { isRendered } = useResponsive(991);
  return (
    <Wrapper>
      <LeftSide>
        {isRendered && (
          <ClickabelIconContainer>
            <ClickableIcon
              isActive={activeMobileSidebar}
              onClick={() => {
                setIsHoveredBox(false);
                setActiveMobileSidebar(!activeMobileSidebar);
              }}
              icon={<SettingsIcon />}
            />
          </ClickabelIconContainer>
        )}
        {leftContent}
      </LeftSide>
      <RightSide>{rightContent}</RightSide>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
position: relative;
@media (max-width: 1190px) {
  justify-content: center;
  gap: ${theme.spacing.spacing20};
  margin-right: 0;
  margin-left: 0;
}
@media (max-width: 991px) {
  display: grid;
}
`
);
const LeftSide = styled.div(
  () => `
flex: 0 0 66.66666667%;
max-width: 66.66666667%;
position: relative;
display: grid;
justify-content: center;
width: 100%;
height: 100%;
@media (max-width: 1190px) {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 991px) {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}
`
);
const RightSide = styled.div(
  () => `
flex: 0 0 33.33333333%;
max-width: 33.33333333%;
@media (max-width: 1190px) {
  flex: 0 0 50%;  
  max-width: 33%;
}
@media (max-width: 991px) {
  flex: 0 0 100%;
  max-width: 95%;
}
`
);

const ClickabelIconContainer = styled.div(
  () => `
  position: absolute;
  right: 0;
  top: -40px;
  max-width: 500px;
`
);
