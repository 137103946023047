import React from 'react';
import { useFacebook } from '../../../context';
import { FacebookAdType } from '../../../types';
import { StoryFormat } from './StoryFormat';
import { NewsFeedFormat } from './NewsFeedFormat';

export const AdFormatWrapper = () => {
  const { adType } = useFacebook();
  switch (adType) {
    case FacebookAdType.NewsFeed:
      return <NewsFeedFormat />;
    case FacebookAdType.Story:
      return <StoryFormat />;
    case FacebookAdType.Messenger:
    case FacebookAdType.Reel:
      return null;
    default:
      return <NewsFeedFormat />;
  }
};
