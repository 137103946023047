import { useTheme } from 'hooks';
import React, { useCallback } from 'react';
import { styled } from 'styled-components';

interface BackdropInterface {
  borderRadius?: boolean;
}

export const Backdrop: React.FC<React.PropsWithChildren & BackdropInterface> = ({
  children,
  borderRadius = false,
}) => {
  const theme = useTheme();
  const getStyle = useCallback(() => {
    return {
      style: {
        borderRadius: borderRadius ? theme.borderRadius.big : '0px',
      },
    };
  }, [borderRadius]);
  return <StyledBackdrop {...getStyle()}>{children}</StyledBackdrop>;
};

const StyledBackdrop = styled.div(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    background-color: ${theme.colors.backdrop};
    transition: all .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      opacity: 1;
    }
    `
);
