import React from 'react';
import { IconInterface } from '../../types';

export const DotsIcon: React.FC<IconInterface> = ({ color = '#6a6d89' }) => {
  return (
    <svg fill={color} viewBox="0 0 20 20" width="16px" height="16px">
      <g fillRule="evenodd" transform="translate(-446 -350)">
        <path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"></path>
      </g>
    </svg>
  );
};
