import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { ButtonVariants } from 'types';
import { Loader } from './Loader';
import { useTheme } from 'hooks';

interface ButtonInterface {
  text: string;
  onClick: () => void;
  variant: ButtonVariants;
  disabled?: boolean;
  loader?: boolean;
}

export const Button: React.FC<ButtonInterface> = ({
  text,
  onClick,
  variant = 'primary',
  disabled = false,
  loader = false,
}) => {
  const theme = useTheme();

  const getDisabledState = useCallback(() => {
    return {
      style: {
        opacity: disabled ? '0.3' : '1',
      },
    };
  }, [disabled]);

  return variant === ButtonVariants.primary ? (
    <Wrapper {...getDisabledState()} disabled={disabled} onClick={onClick}>
      {loader && (
        <LoaderWraper>
          <Loader color={theme.colors.white} width={20} height={20} />
        </LoaderWraper>
      )}
      <span>{text}</span>
    </Wrapper>
  ) : (
    <WrapperTransparent disabled={disabled} onClick={onClick}>
      {text}
    </WrapperTransparent>
  );
};

const Wrapper = styled.button(
  ({ theme }) => `
color: ${theme.colors.white};
background-color: ${theme.colors.secondary};
border-color: ${theme.colors.tertiray};
width: 100%;
padding: ${theme.spacing.spacing8} ${theme.spacing.spacing15};
border-radius: ${theme.borderRadius.small};
cursor: pointer;
border: none;
font-size: ${theme.fontSizes.ultra};

`
);

const WrapperTransparent = styled.button(
  ({ theme }) => `
color: ${theme.colors.secondary};
background-color: ${theme.colors.transparent};
border: ${theme.borderWidth.small} ${theme.borderStyle.solid} ${theme.colors.secondary} !important;
width: 100%;
padding: ${theme.spacing.spacing8} ${theme.spacing.spacing15};
border-radius: ${theme.borderRadius.small};
cursor: pointer;
border: none;
font-size: 18px;
`
);

const LoaderWraper = styled.div(
  () => `
  position: absolute;
`
);
