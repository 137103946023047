import React, { useCallback } from 'react';
import { useFacebook } from 'context';
import { FacebookContentChangeHandler } from '../../../types';
import styled from 'styled-components';
import { useTheme } from 'hooks';

interface CarouselBottomDescriptionInterface {
  id: number;
  newsFeedDescription: string;
  preview?: boolean;
}

export const CarouselBottomDescription: React.FC<CarouselBottomDescriptionInterface> = ({
  id,
  newsFeedDescription,
  preview,
}) => {
  const theme = useTheme();
  const { editCarouselItem, isHoveredBox, deviceType } = useFacebook();

  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor:
          isHoveredBox && !preview ? `${theme.colors.gray}` : `${theme.colors.transparent}`,
      },
    };
  }, [isHoveredBox, preview, deviceType]);

  return (
    <BottomDescription
      {...getStyle()}
      contentEditable={!preview}
      suppressContentEditableWarning
      onBlur={(event) =>
        editCarouselItem(
          id,
          FacebookContentChangeHandler.NewsFeedDescription,
          (event.target as HTMLDivElement).innerHTML
        )
      }>
      {newsFeedDescription}
    </BottomDescription>
  );
};

const BottomDescription = styled.span(
  ({ theme }) => `
    color: ${theme.colors.grayNeutral};
    font-size: ${theme.fontSizes.small};
    font-weight: ${theme.fontWeights.normal};
    outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: calc(${theme.spacing.spacing16} * 2);
    line-height: ${theme.spacing.spacing16};
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    overflow: hidden;
  `
);
