import { useFacebook } from 'context';
import React, { useCallback } from 'react';
import styled from 'styled-components';

interface CarouselArrowInterface {
  icon: React.ReactNode;
  onClick: () => void;
  position: string;
}

export const CarouselArrow: React.FC<CarouselArrowInterface> = ({ icon, onClick, position }) => {
  const { isHoveredBox, showAllSlides } = useFacebook();

  const getActiveStyle = useCallback(() => {
    return {
      style: {
        left: position === 'left' ? '10px' : 'auto',
        right: position === 'right' ? '10px' : 'auto',
      },
    };
  }, [position]);

  return isHoveredBox && !showAllSlides ? (
    <StyledArrow {...getActiveStyle()} onClick={onClick}>
      {icon}
    </StyledArrow>
  ) : null;
};

const StyledArrow = styled.div(
  () => `
    position: absolute;
    cursor: pointer;
    top: 35%;
    z-index: 20;
`
);
