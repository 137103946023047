import { useFacebook } from 'context';
import React, { useCallback, useMemo } from 'react';
import { DeviceType } from 'types';
import placeholder from '../../../assets/images/placeholder-image.png';
import { Loader } from 'components/Loader';

interface CarouselImageInterface {
  image: File | Blob;
  preview?: boolean;
  loading?: boolean;
}

export const CarouselImage: React.FC<CarouselImageInterface> = ({
  image,
  preview = false,
  loading = false,
}) => {
  const { deviceType } = useFacebook();
  const backgroundImage = useMemo(() => {
    return image === undefined ? placeholder : URL.createObjectURL(image!);
  }, [image]);

  const imageStyleHandler = useCallback(() => {
    return {
      style: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: image === undefined ? 'no-repeat' : 'unset',
        height: deviceType === DeviceType.Desktop ? '300px' : '230px',
        width: deviceType === DeviceType.Desktop ? '100%' : preview ? '100%' : '240px',
      },
    };
  }, [deviceType, preview, image]);

  const getLoaderContainerStyle = useCallback(() => {
    return {
      style: {
        height: deviceType === DeviceType.Desktop ? '300px' : '230px',
        width: deviceType === DeviceType.Desktop ? '100%' : preview ? '100%' : '240px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
  }, [loading]);

  return loading ? (
    <div {...getLoaderContainerStyle()}>
      <Loader />
    </div>
  ) : (
    <div {...imageStyleHandler()} />
  );
};
