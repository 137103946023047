import React, { useCallback } from 'react';
import { useFacebook } from '../../../context';
import styled from 'styled-components';
import { DeviceType, FacebookContentChangeHandler } from 'types';
import { useTheme } from 'hooks';

export const CarouselPlaceholderHeadline = () => {
  const theme = useTheme();
  const { placeholderData, editPlaceholderNewsFeedItem, isHoveredBox, deviceType } = useFacebook();
  const { newsFeedDescriptionSubtitle } = placeholderData;

  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor: isHoveredBox ? `${theme.colors.gray}` : `${theme.colors.transparent}`,
        fontSize: deviceType === DeviceType.Desktop ? theme.fontSizes.big : theme.fontSizes.body,
      },
    };
  }, [isHoveredBox, deviceType]);

  return (
    <Wrapper
      {...getStyle()}
      onBlur={(e) => {
        const content = (e.target as HTMLDivElement).innerText; // Use textContent instead of innerHTML
        editPlaceholderNewsFeedItem(
          FacebookContentChangeHandler.NewsFeedDescriptionSubtitle,
          content!
        );
      }}
      contentEditable
      suppressContentEditableWarning>
      {newsFeedDescriptionSubtitle}
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
  color: ${theme.colors.blackMiddle};
  font-weight: ${theme.fontWeights.semibold};
  overflow: hidden;
  outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
  margin-bottom: ${theme.spacing.spacing3};
  width: fit-content;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  max-height: calc(${theme.spacing.spacing32} * 2);
  line-height: 1.2em;
  display: -webkit-box; 
  -webkit-line-clamp: 2;
`
);
