import React from 'react';
import { MenuItem } from './MenuItem';
import styled from 'styled-components';
import { innerNavigation, navigationMenu } from './data';

type NavigationMenuMobileProps = {
  onClick?: () => void;
};

export const NavigationMenuMobile: React.FC<NavigationMenuMobileProps> = ({ onClick }) => {
  return (
    <Container>
      <Wrapper>
        {innerNavigation.map((item) => (
          <MenuItem onClick={onClick} key={item.id} name={item.name} link={item.link} inner />
        ))}
      </Wrapper>
      <Wrapper>
        {navigationMenu.map((item) => (
          <MenuItem key={item.id} name={item.name} link={item.link} />
        ))}
      </Wrapper>
    </Container>
  );
};
const Container = styled.div(
  () => `
display: flex;
flex-direction: column;
justify-content: space-between;
`
);
const Wrapper = styled.ul(
  ({ theme }) => `
    background-color: ${theme.colors.primary};
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${theme.spacing.spacing0} ${theme.spacing.spacing15};
  `
);
