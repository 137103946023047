import React from 'react';
import { styled } from 'styled-components';

interface BoxTitleInterface {
  text: string;
}
export const BoxTitle: React.FC<BoxTitleInterface> = ({ text }) => {
  return <StyledBoxTitle>{text}</StyledBoxTitle>;
};

const StyledBoxTitle = styled.h3(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.spacing10};
`
);
