import React, { ComponentProps, ComponentType, FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { useTheme } from '../hooks';
import { FacebookProvider } from '../context';
import { theme } from '../theme';

type Providers = [ComponentType<any>, ComponentProps<any>?][];

const combineProviders = (providers: Providers): FC<React.PropsWithChildren> =>
  providers.reduce(
    (AccumulatedProviders, [Provider, props = {}]) => {
      const Combined: FC<React.PropsWithChildren> = ({ children }) => {
        const theme = useTheme();

        return (
          <AccumulatedProviders>
            <Provider {...props}>
              <ThemeProvider theme={theme}>
                <FacebookProvider>
                  <>{children}</>
                </FacebookProvider>
              </ThemeProvider>
            </Provider>
          </AccumulatedProviders>
        );
      };

      const providerName = Provider.displayName || Provider.name || 'Unknown';
      Combined.displayName = `CombinedProvider(${providerName})`;

      return Combined;
    },
    ({ children }) => <>{children}</>
  );

export const AllProviders = combineProviders([[ThemeProvider, { theme }], [FacebookProvider]]);

AllProviders.displayName = 'AllProviders';
