import React from 'react';
import { BoxTitle } from '../../BoxTitle';
import { DeviceType, FacebookSettings } from '../../../types';
import { SelectableBox } from '../../SelectableBox';
import { FlexRow } from '../../layout';
import { useFacebook } from '../../../context';
import { capitalizeFirstLowercaseRest } from '../../../helpers';
import { Divider } from '../../Divider';
import { DesktopIcon, MobileIcon } from '../../../assets';
import { useResponsive } from 'hooks';

export const DeviceSize = () => {
  const { deviceType, setDeviceType, setActiveMobileSidebar } = useFacebook();
  const { isRendered } = useResponsive(991);

  return (
    <div>
      <BoxTitle text={FacebookSettings.DeviceType} />
      <FlexRow>
        {!isRendered && (
          <SelectableBox
            active={deviceType === DeviceType.Desktop}
            icon={<DesktopIcon />}
            text={capitalizeFirstLowercaseRest(DeviceType.Desktop)}
            onClick={() => {
              setDeviceType(DeviceType.Desktop);
              setActiveMobileSidebar(false);
            }}
          />
        )}
        <SelectableBox
          active={deviceType === DeviceType.Mobile || isRendered}
          icon={<MobileIcon />}
          text={capitalizeFirstLowercaseRest(DeviceType.Mobile)}
          onClick={() => {
            setDeviceType(DeviceType.Mobile);
            setActiveMobileSidebar(false);
          }}
        />
      </FlexRow>
      <Divider />
    </div>
  );
};
