import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { HamburgerIcon, Logo } from '../assets/svg';
import { IconLink } from './IconLink';
import { NavigationMenu, NavigationMenuMobile } from './NavigationMenu';
import { useResponsive } from '../hooks';

export const Header = () => {
  const { isRendered } = useResponsive(1110);
  const [activeMobileMenu, setActiveMobileMenu] = useState<boolean>(false);

  const getActiveStyle = useCallback(() => {
    return {
      style: {
        justifyContent: isRendered ? 'space-between' : 'flex-start',
      },
    };
  }, [isRendered, activeMobileMenu]);

  const getOuterActiveStyle = useCallback(() => {
    return {
      style: {
        maxHeight: activeMobileMenu ? '420px' : '63px',
        overflow: 'hidden',
        transition: 'all 0.1s linear',
      },
    };
  }, [isRendered, activeMobileMenu]);

  return (
    <OuterWrapper {...getOuterActiveStyle()}>
      <InnerWrapper {...getActiveStyle()}>
        <LogoContainer>
          <IconLink href="https://mockupbro.com/">
            <Logo />
          </IconLink>
        </LogoContainer>
        {!isRendered ? (
          <NavigationMenu />
        ) : (
          <HamburgerIconContainer onClick={() => setActiveMobileMenu(!activeMobileMenu)}>
            <HamburgerIcon />
          </HamburgerIconContainer>
        )}
      </InnerWrapper>
      {isRendered && activeMobileMenu && (
        <NavigationMenuMobile onClick={() => setActiveMobileMenu(false)} />
      )}
    </OuterWrapper>
  );
};
const OuterWrapper = styled.div(() => ``);
const InnerWrapper = styled.nav(
  ({ theme }) => `
    display: flex;
    align-items: center;
    background: ${theme.colors.primary};
    padding: 0 ${theme.spacing.spacing15}  ${theme.spacing.spacing0}  ${theme.spacing.spacing15};
    `
);

const LogoContainer = styled.div(
  () => `
  padding: .32rem 0;
  margin-right: 1rem;
  margin-top: 7px;
`
);
const HamburgerIconContainer = styled.div(
  () => `
    padding: 0.25rem 0.75rem;
    font-size: 1.125rem;
    line-height: 1;
    border: 1px solid hsla(0,0%,100%,.1);
    border-radius: 0.25rem;
    cursor: pointer;
    height: 30px;
    width: 30px;
`
);
