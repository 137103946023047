import React from 'react';
import { BoxTitle } from '../../BoxTitle';
import { FacebookAdType, FacebookSettings, SelectFormat } from '../../../types';
import { FlexRow } from '../../layout';
import { SelectableBox } from '../../SelectableBox';
import { useFacebook } from '../../../context';
import { NewsFeedIcon, StoryIcon } from '../../../assets';

export const AdType = () => {
  const { adType, setAdType, setSelectFormat, setActiveMobileSidebar } = useFacebook();

  return (
    <>
      <BoxTitle text={FacebookSettings.AdType} />
      <FlexRow>
        <SelectableBox
          active={adType === FacebookAdType.NewsFeed}
          icon={<NewsFeedIcon />}
          text={FacebookAdType.NewsFeed}
          onClick={() => {
            setAdType(FacebookAdType.NewsFeed);
            setSelectFormat(SelectFormat.NewsFeed);
            setActiveMobileSidebar(false);
          }}
        />
        <SelectableBox
          active={adType === FacebookAdType.Story}
          icon={<StoryIcon />}
          text={FacebookAdType.Story}
          onClick={() => {
            setAdType(FacebookAdType.Story);
            setSelectFormat(SelectFormat.Story);
            setActiveMobileSidebar(false);
          }}
        />
      </FlexRow>
    </>
  );
};
