import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { useFacebook } from '../../../../context';
import { useTheme } from '../../../../hooks';
import { FacebookContentChangeHandler } from '../../../../types';

export const NewsFeedMainDescription = () => {
  const { isHoveredBox, newsFeedItem, handleContentEditableChange } = useFacebook();
  const theme = useTheme();
  const { newsFeedMainDescription } = newsFeedItem;
  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor: isHoveredBox ? `${theme.colors.grayNeutral}` : `${theme.colors.transparent}`,
      },
    };
  }, [isHoveredBox]);

  return (
    <>
      <StyledText
        contentEditable
        suppressContentEditableWarning
        {...getStyle()}
        onBlur={(e) =>
          handleContentEditableChange(e, FacebookContentChangeHandler.NewsFeedMainDescription)
        }
        placeholder="Enter your text here">
        {newsFeedMainDescription || 'deki2'}
      </StyledText>
    </>
  );
};

const StyledText = styled.div(
  ({ theme }) => `
    margin-top: ${theme.spacing.spacing12};
    color: ${theme.colors.blackMiddle};
    font-size: ${theme.fontSizes.big};
    overflow: hidden;
    outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
    width: 95%;
    word-break: break-word;
`
);
