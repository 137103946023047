import { useTheme } from 'hooks';
import React, { useCallback, useState } from 'react';
import { styled } from 'styled-components';

interface ClickableIconInterface {
  onClick: () => void;
  icon: React.ReactNode;
  isActive: boolean;
}

export const ClickableIcon: React.FC<ClickableIconInterface> = ({ icon, onClick, isActive }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const theme = useTheme();

  const getActiveStyle = useCallback(() => {
    return {
      style: {
        background: isHovered || isActive ? theme.colors.grayMid : theme.colors.transparent,
      },
    };
  }, [isHovered, isActive]);

  return (
    <Wrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...getActiveStyle()}
      onClick={onClick}>
      {icon}
    </Wrapper>
  );
};

const Wrapper = styled.div(
  () => `
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
`
);
