import React, { ForwardedRef, RefObject, useCallback } from 'react';
import { BaseModal } from './BaseModal';
import { BaseModalRef, ButtonValues, ButtonVariants } from 'types';
import { useFacebook } from 'context';
import styled from 'styled-components';
import { CloseIcon } from 'assets';
import { useTheme } from 'hooks';
import { Button } from './Button';
import { CarouselItem } from './facebookGenerator/Carousel/CarouselItem';

export const DeleteModal = React.forwardRef((_, ref: ForwardedRef<BaseModalRef>) => {
  const { itemToDelete, deleteCarouselItem, setIsHoveredBox, setIsHoveredSlider } = useFacebook();
  const theme = useTheme();

  const closeHandler = () => (ref as RefObject<BaseModalRef>)?.current?.close();

  const deleteHandler = () => {
    deleteCarouselItem();
    (ref as RefObject<BaseModalRef>)?.current?.close();
    setIsHoveredBox(false);
    setIsHoveredSlider(false);
  };

  const itemToDeleteHandler = useCallback(() => {
    if (itemToDelete) {
      return (
        <CarouselItemContainer>
          <CarouselItem
            preview
            id={itemToDelete.id}
            newsFeedMainImage={itemToDelete.newsFeedMainImage}
            newsFeedSelectValue={{
              id: itemToDelete.newsFeedSelectValue.id,
              label: itemToDelete.newsFeedSelectValue.label,
            }}
            newsFeedDescriptionSubtitle={itemToDelete.newsFeedDescriptionSubtitle}
            newsFeedDescription={itemToDelete.newsFeedDescription}
          />
        </CarouselItemContainer>
      );
    }
  }, [itemToDelete]);

  return (
    <BaseModal ref={ref}>
      <Content>
        <CloseIconContainer onClick={closeHandler}>
          <CloseIcon color={theme.colors.secondary} />
        </CloseIconContainer>
        <Title>Are you sure you want to remove this item?</Title>
        {itemToDeleteHandler()}
        <ButtonWrapper>
          <Button
            variant={ButtonVariants.primary}
            text={ButtonValues.delete}
            onClick={deleteHandler}
          />
          <Button
            variant={ButtonVariants.transparent}
            text={ButtonValues.cancel}
            onClick={closeHandler}
          />
        </ButtonWrapper>
      </Content>
    </BaseModal>
  );
});

const Content = styled.div(
  ({ theme }) => `
  position: absolute;
  max-width: 330px;
  padding: ${theme.spacing.spacing32};
  border-radius: ${theme.borderRadius.big};
  height: fit-content;
  background: ${theme.colors.white};
  left: 0;
  right: 0;
  margin: auto auto;
  top: 0;
  bottom: 0;
`
);

const CloseIconContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing.spacing10};
  top: ${theme.spacing.spacing10};
  cursor: pointer;
`
);

const ButtonWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.spacing10};
  margin-top: ${theme.spacing.spacing20};
`
);

const Title = styled.p(
  ({ theme }) => `
color: ${theme.colors.blackMiddle};
font-size: ${theme.fontSizes.mega};
font-weight: ${theme.fontWeights.semibold};
margin-bottom: ${theme.spacing.spacing20};
`
);

const CarouselItemContainer = styled.div(
  () => `
  max-width: 300px;
  margin: 0 auto;
`
);

DeleteModal.displayName = 'DeleteModal';
