import React from 'react';
import { styled } from 'styled-components';

export const FlexRow: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledFlexRow>{children}</StyledFlexRow>;
};

export const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
