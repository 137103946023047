import React from 'react';
import { CarouselValues, FacebookSettings } from '../../../types';
import { BoxTitle } from '../../BoxTitle';
import { Switch } from 'components';
import { useFacebook } from 'context';
export const CarouselFormat = () => {
  const { showAllSlides, setShowAllSlides } = useFacebook();
  return (
    <>
      <BoxTitle text={FacebookSettings.AdOptions} />
      <Switch
        checked={showAllSlides}
        onChange={() => setShowAllSlides(!showAllSlides)}
        label={CarouselValues.ShowAll}
      />
    </>
  );
};
