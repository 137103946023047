import * as React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { Header } from './components/Header';
import { Container, Footer } from './components';
import { Facebook, Instagram, Page404 } from './pages';
const Layout = () => (
  <>
    <Header />
    <Container>
      <Outlet />
    </Container>
    <Footer />
  </>
);

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Facebook />,
      },
      {
        path: '/facebook',
        element: <Facebook />,
      },
      {
        path: '/instagram',
        element: <Instagram />,
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
]);
