import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Dropdown } from '../dropdown';
import { useFacebook } from 'context';

interface SelectValues {
  value: { id: number; label: string; carouselItemId?: number };
  disabled?: boolean;
  placeholder?: boolean;
}
export const Select: React.FC<SelectValues> = ({
  value,
  disabled = false,
  placeholder = false,
}) => {
  const [visibleSelect, setVisibleSelect] = useState<boolean>(true);
  const [isVisibleDropdown, setIsVisibleDropdown] = useState<boolean>(false);
  const { isHoveredBox } = useFacebook();

  useEffect(() => {
    switch (value.id) {
      case 0:
        if (isHoveredBox && value.id === 0) {
          setVisibleSelect(true);
        } else {
          setVisibleSelect(false);
        }
        break;
      default:
        setVisibleSelect(true);
    }
  }, [value.id, isHoveredBox]);

  return visibleSelect ? (
    <Wrapper>
      {isVisibleDropdown && (
        <Dropdown
          placeholder={placeholder}
          value={value}
          visible={isVisibleDropdown}
          setVisible={setIsVisibleDropdown}
        />
      )}
      <Trigger
        onClick={() => {
          !disabled && setIsVisibleDropdown(!isVisibleDropdown);
        }}>
        {value.label}
      </Trigger>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div(
  ({ theme }) => `
  position: relative;
  background-color: ${theme.colors.grayButton};
  padding: ${theme.spacing.spacing6} ${theme.spacing.spacing12};
  border-radius: ${theme.borderRadius.small};
  height: 25px;
  text-align: center;
  display: flex;
  max-width: fit-content;
  width: 100%;
  text-wrap: nowrap;
  white-space: nowrap;
`
);
const Trigger = styled.div(
  ({ theme }) => `
    cursor: pointer;
    font-size: ${theme.fontSizes.big};
    font-weight: 600;
    color: ${theme.colors.blackMiddle};
    display: flex;
    align-items: center;
    width: 100%;
`
);
