import React, { ForwardedRef, useCallback } from 'react';
import { styled } from 'styled-components';
import { FileInput } from '../../../FileInput';
import { FacebookContentChangeHandler, FacebookImages } from '../../../../types';
import { useFacebook } from '../../../../context';
import { ProfilePhoto } from '../../components/ProfilePhoto';
import placeholder from '../../../../assets/images/facebook-placeholder.png';
import { useTheme } from '../../../../hooks';
import { PlanetIcon } from '../../../../assets';

export const NewsFeedMainContent = React.forwardRef((_, ref: ForwardedRef<HTMLInputElement>) => {
  const { handleCompressedUpload, newsFeedItem, isHoveredBox, handleContentEditableChange } =
    useFacebook();

  const { newsFeedProfileImage, newsFeedAddressTitle } = newsFeedItem;

  const theme = useTheme();
  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor: isHoveredBox ? `${theme.colors.grayNeutral}` : `${theme.colors.transparent}`,
      },
    };
  }, [isHoveredBox]);

  return (
    <ItemsContainer>
      <FileInput
        ref={ref}
        onChange={(e) =>
          handleCompressedUpload(e, FacebookImages.NewsFeedProfileImage, newsFeedItem.id)
        }
      />
      <ProfilePhoto placeholder={placeholder} selectedImage={newsFeedProfileImage} />
      <TitleContainer>
        <AddressTitle
          {...getStyle()}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) =>
            handleContentEditableChange(e, FacebookContentChangeHandler.NewsFeedAddressTitle)
          }>
          {newsFeedAddressTitle}
        </AddressTitle>
        <SponsoredContainer>
          <SponsoredText>
            Sponsored
            <Dot />
          </SponsoredText>
          <PlanetIcon />
        </SponsoredContainer>
      </TitleContainer>
    </ItemsContainer>
  );
});

const ItemsContainer = styled.div(
  () => `
    display: flex;
    align-items: center;
  `
);

const TitleContainer = styled.div(
  ({ theme }) => `
    margin-left: ${theme.spacing.spacing8}
  `
);

const AddressTitle = styled.p(
  ({ theme }) => `
    overflow: hidden;
    outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
    width: fit-content;
    word-break: break-word;
    font-size: ${theme.fontSizes.body};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.blueNeutral}
    `
);

const SponsoredContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing.spacing5};
    align-items: center;
  `
);

const Dot = styled.span(
  ({ theme }) => `
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: ${theme.colors.grayNeutral};
  border-radius: ${theme.borderRadius.half};
  margin-left: ${theme.spacing.spacing4}
  `
);
const SponsoredText = styled.p(
  ({ theme }) => `
    color: ${theme.colors.grayNeutral};
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizes.small}
  `
);

NewsFeedMainContent.displayName = 'NewsFeedMainContent';
