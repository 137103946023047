import React from 'react';
import { useFacebook } from '../../../context';
import { BoxTitle } from '../../BoxTitle';
import { FacebookSettings, StoryAdFormat } from '../../../types';
import { SelectableBox } from '../../SelectableBox';
import { FlexRow } from '../../layout';
import { LandscapeIcon, PortraitIcon } from '../../../assets';

export const StoryFormat = () => {
  const { storyFormat, setStoryFormat, setActiveMobileSidebar } = useFacebook();

  return (
    <>
      <BoxTitle text={FacebookSettings.AdFormat} />
      <FlexRow>
        <SelectableBox
          active={storyFormat === StoryAdFormat.Original}
          icon={<LandscapeIcon />}
          text={StoryAdFormat.Original}
          onClick={() => {
            setStoryFormat(StoryAdFormat.Original);
            setActiveMobileSidebar(false);
          }}
        />
        <SelectableBox
          active={storyFormat === StoryAdFormat.Cover}
          icon={<PortraitIcon />}
          text={StoryAdFormat.Cover}
          onClick={() => {
            setStoryFormat(StoryAdFormat.Cover);
            setActiveMobileSidebar(false);
          }}
        />
      </FlexRow>
    </>
  );
};
