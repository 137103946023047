import React, { FC } from 'react';
import styled from 'styled-components';

import { PenIcon } from '../../../assets';
import { DeleteIcon } from '../../../assets';
import { NewsFeedCarouselItem } from 'types';
import { useFacebook } from '../../../context';

interface CarouselItemActionsInterface {
  onUpload: () => void;
  onDelete?: () => void;
  item: NewsFeedCarouselItem;
}
export const CarouselItemActions: FC<CarouselItemActionsInterface> = ({
  onUpload,
  item,
  onDelete,
}) => {
  const { isHoveredBox, carouselData, setItemToDelete } = useFacebook();

  return isHoveredBox ? (
    <>
      <Wrapper>
        <IconContainer onClick={onUpload}>
          <PenIcon width={30} height={30} />
        </IconContainer>
        {carouselData.length > 1 && (
          <IconContainer
            onClick={() => {
              onDelete && onDelete();
              setItemToDelete(item);
            }}>
            <DeleteIcon />
          </IconContainer>
        )}
      </Wrapper>
    </>
  ) : null;
};

export const Wrapper = styled.div(
  ({ theme }) => `
    position: absolute;
    z-index: 21;
    top: ${theme.spacing.spacing10};
    right: ${theme.spacing.spacing12};
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing.spacing10};
    cursor: pointer;
    `
);

const IconContainer = styled.div(
  ({ theme }) => `
  background: ${theme.colors.backdrop};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
);
