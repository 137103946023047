import React from 'react';
import styled from 'styled-components';
import { Button } from '../components';
import { useNavigate } from 'react-router-dom';
import { ButtonVariants } from '../types';

export const Page404 = () => {
  const navigation = useNavigate();
  const clickHandler = () => navigation('/');
  return (
    <>
      <Status>404</Status>
      <Message>Oops! Page Not Found. Let's get you back on track.</Message>
      <ButtonContainer>
        <Button text={'Back to Home'} onClick={clickHandler} variant={ButtonVariants.primary} />
      </ButtonContainer>
    </>
  );
};

const Status = styled.h1(
  ({ theme }) => `
    font-size: ${theme.fontSizes.giant};
    text-align: center;
`
);

const Message = styled.h2(
  ({ theme }) => `
    font-size: ${theme.fontSizes.giga};
    text-align: center;
`
);

const ButtonContainer = styled.div(
  ({ theme }) => `
    max-width: 300px;
    margin: ${theme.spacing.spacing32} auto;

`
);
