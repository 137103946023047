import React from 'react';
import { styled } from 'styled-components';
import { useResponsive } from '../../../hooks';
import { useFacebook } from 'context';

export const FacebookOptionsContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isRendered } = useResponsive(991);
  const { activeMobileSidebar } = useFacebook();
  return isRendered ? (
    activeMobileSidebar ? (
      <SmallDeviceWrapper>{children}</SmallDeviceWrapper>
    ) : null
  ) : (
    <Wrapper>{children}</Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing.spacing12} ${theme.spacing.spacing20};
    border-radius: ${theme.borderRadius.small};
    -webkit-box-shadow: 0 2px 8px 0 rgba(36,48,66,.1);
    -moz-box-shadow: 0 2px 8px 0 rgba(36,48,66,.1);
    box-shadow: 0 2px 8px 0 rgba(36,48,66,.1);
`
);

const SmallDeviceWrapper = styled(Wrapper)(
  ({ theme }) => `
    position: absolute;
    top: 0;
    background: ${theme.colors.white};
    z-index: 1030;

`
);
