import React from 'react';
import { BoxTitle } from '../../BoxTitle';
import { FacebookSettings, NewsFeedAdFormat, SelectFormat } from '../../../types';
import { FlexRow } from '../../layout';
import { SelectableBox } from '../../SelectableBox';
import { useFacebook } from '../../../context';
import { CarouselIcon, SingleImageIcon } from '../../../assets';

export const NewsFeedFormat = () => {
  const { newsFeedFormat, setNewsFeedFormat, setSelectFormat, setActiveMobileSidebar } =
    useFacebook();
  return (
    <>
      <BoxTitle text={FacebookSettings.AdFormat} />
      <FlexRow>
        <SelectableBox
          active={newsFeedFormat === NewsFeedAdFormat.SingleImage}
          icon={<SingleImageIcon />}
          text={NewsFeedAdFormat.SingleImage}
          onClick={() => {
            setNewsFeedFormat(NewsFeedAdFormat.SingleImage);
            setSelectFormat(SelectFormat.NewsFeed);
            setActiveMobileSidebar(false);
          }}
        />
        <SelectableBox
          active={newsFeedFormat === NewsFeedAdFormat.Carousel}
          icon={<CarouselIcon />}
          text={NewsFeedAdFormat.Carousel}
          onClick={() => {
            setNewsFeedFormat(NewsFeedAdFormat.Carousel);
            setSelectFormat(SelectFormat.NewsFeedCarousel);
            setActiveMobileSidebar(false);
          }}
        />
      </FlexRow>
    </>
  );
};
