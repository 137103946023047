export const data = [
  { id: 0, label: 'No Button' },
  {
    id: 1,
    label: 'Apply Now',
  },
  {
    id: 2,
    label: 'Book Now',
  },
  {
    id: 3,
    label: 'Call Now',
  },
  {
    id: 4,
    label: 'Contact Us',
  },
  {
    id: 5,
    label: 'Donate Now',
  },
  {
    id: 6,
    label: 'Download',
  },
  {
    id: 7,
    label: 'Get Access',
  },
  {
    id: 8,
    label: 'Get Offer',
  },
  {
    id: 9,
    label: 'Get Quote',
  },
  {
    id: 10,
    label: 'Get Showtimes',
  },
  {
    id: 11,
    label: 'Learn More',
  },
  {
    id: 12,
    label: 'Listen Now',
  },
  {
    id: 13,
    label: 'Order Now',
  },
  {
    id: 14,
    label: 'Play Game',
  },
  {
    id: 15,
    label: 'Request Time',
  },
  {
    id: 16,
    label: 'See Menu',
  },
];
