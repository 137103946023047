import { useEffect, useMemo, useState } from 'react';

export const useResponsive = (minWidth: number) => {
  const [isRendered, setIsRendered] = useState<boolean>(window.innerWidth >= minWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsRendered(window.innerWidth <= minWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [minWidth, isRendered]);

  return useMemo(
    () => ({
      isRendered,
    }),
    [isRendered, minWidth]
  );
};
