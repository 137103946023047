import React from 'react';
import { NavigationBox } from '../components';
import { FacebookLogo, InstagramLogo } from '../assets';
import styled from 'styled-components';

const boxes = [
  {
    link: '/facebook',
    text: 'Facebook',
    icon: <FacebookLogo width={50} height={50} />,
  },
  {
    link: '/instagram',
    text: 'Instagram',
    icon: <InstagramLogo width={50} height={50} />,
  },
];

export const Home = () => {
  return (
    <Container>
      <Title>Welcome to MockupBro: Your Ultimate Mockup Generator</Title>
      <Subtitle>
        Create stunning visuals effortlessly with MockupBro, your go-to platform for generating
        high-quality mockups. Whether you're showcasing designs, products, or presentations, our
        user-friendly tool empowers you to bring your ideas to life. Choose from a diverse range of
        mockup templates, customize with ease, and elevate your projects to the next level.
        MockupBro - Where Imagination Meets Reality.
      </Subtitle>
      <BoxesContainer>
        {boxes.map((item) => (
          <NavigationBox {...item} />
        ))}
      </BoxesContainer>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing.spacing32};
    padding: ${theme.spacing.spacing0} ${theme.spacing.spacing32}
`
);

const BoxesContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing.spacing32}
`
);

const Title = styled.h1(
  () => `
    text-align: center;
`
);

const Subtitle = styled.h3(
  () => `
    text-align: center;
`
);
