import React, { ForwardedRef } from 'react';
import { useFacebook } from '../../context';
import { FacebookAdType } from '../../types';
import { NewsFeed } from './NewsFeed';
import { Story } from './Story/Story';

export const FacebookGenerator = React.forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { adType } = useFacebook();
  switch (adType) {
    case FacebookAdType.NewsFeed:
      return <NewsFeed ref={ref} />;
    case FacebookAdType.Story:
      return <Story ref={ref} />;
    default:
      return <NewsFeed ref={ref} />;
  }
});
FacebookGenerator.displayName = 'FacebookGenerator';
