import React from 'react';
import { FacebookImages, NewsFeedCarouselItem } from '../../../types';
import { styled } from 'styled-components';
import { Select } from '../../select';
import { CarouselImage } from './CarouselImage';
import { CarouselItemActions } from './CarouselItemActions';
import { useFacebook } from 'context';
import { CarouselSubtitle } from './CarouselSubtitle';
import { CarouselBottomDescription } from './CarouselBottomDescription';
import { FileInput } from 'components/FileInput';

interface CarouselItemInterface {
  onDelete?: () => void;
  preview?: boolean;
}

export const CarouselItem: React.FC<NewsFeedCarouselItem & CarouselItemInterface> = ({
  id,
  newsFeedMainImage,
  newsFeedSelectValue,
  newsFeedDescriptionSubtitle,
  newsFeedDescription,
  onDelete,
  preview = false,
}) => {
  const passedItem = {
    id,
    newsFeedMainImage,
    newsFeedSelectValue,
    newsFeedDescriptionSubtitle,
    newsFeedDescription,
  };
  const { handleCompressedUpload, loadingCarouselItemId } = useFacebook();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Wrapper>
      <FileInput
        ref={fileInputRef}
        onChange={(e) => handleCompressedUpload(e, FacebookImages.CarouselImage, id)}
      />
      <CarouselImage
        loading={loadingCarouselItemId === id}
        preview={preview}
        image={newsFeedMainImage!}
      />
      {!preview && (
        <CarouselItemActions
          onDelete={() => onDelete && onDelete()}
          item={passedItem}
          onUpload={() => fileInputRef.current?.click()}
        />
      )}
      <BottomContent>
        <ContentWrapper>
          <CarouselSubtitle
            preview={preview}
            id={id}
            newsFeedDescriptionSubtitle={newsFeedDescriptionSubtitle}
          />
          <CarouselBottomDescription
            preview={preview}
            id={id}
            newsFeedDescription={newsFeedDescription}
          />
        </ContentWrapper>
        <Select
          disabled={preview}
          value={{
            id: newsFeedSelectValue.id,
            label: newsFeedSelectValue.label,
            carouselItemId: id,
          }}
        />
      </BottomContent>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
    border: 1px solid #ccc;
    border-radius: ${theme.borderRadius.big};
    overflow: hidden;
    margin-right: ${theme.spacing.spacing10};
    position: relative;
    background: ${theme.colors.white};
`
);

const BottomContent = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing.spacing12} ${theme.spacing.spacing16};
  background-color: ${theme.colors.grayMid};
  border-bottom: ${theme.borderWidth.small} ${theme.borderStyle.solid} ${theme.colors.grayMid};
  align-items: center;
  gap: ${theme.spacing.spacing5};
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.spacing10}
`
);

const ContentWrapper = styled.div(
  () => `
  display: grid;
`
);
