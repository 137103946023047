import React from 'react';
import { BallTriangle } from 'react-loader-spinner';

interface LoaderInterface {
  height?: number;
  width?: number;
  color?: string;
}

export const Loader: React.FC<LoaderInterface> = ({
  height = 50,
  width = 50,
  color = '#5e72e4',
}) => {
  return (
    <BallTriangle
      height={`${height}px`}
      width={`${width}px`}
      color={color}
      ariaLabel="circles-loading"
      wrapperClass=""
      visible={true}
    />
  );
};
