import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { AllProviders } from './utils/combineProviders';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AllProviders>
    <RouterProvider router={router} />
  </AllProviders>
);
