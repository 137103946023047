import React, { useRef } from 'react';
import { Divider } from '../../Divider';
import { Button } from '../../Button';
import { AdType } from '../AdTypes/AdType';
import { DeviceSizeWrapper } from '../DeviceSize/DeviceSizeWrapper';
import { useFacebook } from '../../../context';
import { AdFormatWrapper } from '../AdFormat';
import { CarouselFormatWrapper } from '../CarouselFormat';
import { ToastContainer, toast } from 'react-toastify';
import { ButtonValues, ButtonVariants } from 'types';
import { FacebookOptionsContainer } from './FacebookOptionsContainer';
import 'react-toastify/dist/ReactToastify.css';
import { CheckIcon } from 'assets';
import styled from 'styled-components';
import { useClickOutside } from 'hooks';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';

export const FacebookOptions = React.forwardRef((_, ref: any) => {
  const { setActiveMobileSidebar, loading } = useFacebook();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const closeFacebookOptions = () => {
    setActiveMobileSidebar(false);
  };

  useClickOutside(dropdownRef, closeFacebookOptions);

  const exportAsImage = async (el: HTMLElement, imageFileName: string) => {
    const canvas = await html2canvas(el, { backgroundColor: 'transparent' });
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
  };
  const downloadImage = (blob: string, fileName: string) => {
    const fakeLink = window.document.createElement('a');
    fakeLink.download = fileName;
    fakeLink.href = blob;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    closeFacebookOptions();
    if (!document.body.contains(fakeLink)) {
      toast.success('Succesfully downloaded!', {
        icon: <CheckIcon />,
      });
    }
  };

  return (
    <div ref={dropdownRef}>
      <StyledToastContainer autoClose={1500} />
      <FacebookOptionsContainer>
        <DeviceSizeWrapper />
        <AdType />
        <Divider />
        <AdFormatWrapper />
        <Divider />
        <CarouselFormatWrapper />
        <Button
          disabled={loading}
          variant={ButtonVariants.primary}
          text={ButtonValues.download}
          onClick={() => exportAsImage(ref.current, `mockup-bro-${uuidv4()}`)}
        />
      </FacebookOptionsContainer>
    </div>
  );
});

const StyledToastContainer = styled(ToastContainer)(
  ({ theme }) => `
.Toastify__progress-bar {
  background-color: ${theme.colors.secondary};
}
`
);

FacebookOptions.displayName = 'FacebookOptions';
