import React, { ForwardedRef, useCallback } from 'react';
import { styled } from 'styled-components';
import { StoryImageUpload } from './StoryImageUpload';
import { StorySelect } from './StorySelect';
import { StoryTopContent } from './StoryTopContent';
import { ArrowUp } from '../../../assets';
import { StoryMainDescription } from './StoryMainDescription';
import { useFacebook } from '../../../context';
import { useColorExtractor, useTheme } from 'hooks';
import { Loader } from 'components/Loader';

export const Story = React.forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const theme = useTheme();
  const { setIsHoveredBox, storyItem, loading } = useFacebook();
  const { storyMainImage } = storyItem;
  const { primaryColor, hasTransparentBackground } = useColorExtractor(storyMainImage);

  const getStyle = useCallback(() => {
    return {
      style: {
        backgroundColor: hasTransparentBackground
          ? theme.colors.black
          : primaryColor
          ? primaryColor
          : theme.colors.black,
      },
    };
  }, [primaryColor, storyMainImage]);

  return (
    <StyledWrapper
      {...getStyle()}
      ref={ref}
      onMouseEnter={() => setIsHoveredBox(true)}
      onMouseLeave={() => setIsHoveredBox(false)}>
      <StoryTopContent />
      {loading ? <Loader /> : <StoryImageUpload />}
      <StoryMainDescription />
      <SelectContainer>
        <ArrowUp />
        <StorySelect />
      </SelectContainer>
    </StyledWrapper>
  );
});

Story.displayName = 'Story';

const StyledWrapper = styled.div(
  ({ theme }) => `
    position: relative;
    height: 575px;
    width: 320px;
    border-radius: ${theme.borderRadius.big};
    display: flex;
    justify-content: center;
    align-items: center;
`
);

const SelectContainer = styled.div(
  ({ theme }) => `
  margin: 0 auto;
  position: absolute;
  z-index: 100;
  bottom: 16px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing.spacing10}
`
);
