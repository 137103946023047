import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { useFacebook } from '../../../context';
import { FacebookContentChangeHandler } from '../../../types';
import { useTheme } from '../../../hooks';
import { Select } from '../../select';

export const NewsFeedDescription = () => {
  const { newsFeedItem, handleContentEditableChange, isHoveredBox, carouselData } = useFacebook();
  const theme = useTheme();

  const {
    newsFeedDescriptionTitle,
    newsFeedDescriptionSubtitle,
    newsFeedDescription,
    newsFeedSelectValue,
  } = newsFeedItem;

  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor: isHoveredBox ? `${theme.colors.gray}` : `${theme.colors.transparent}`,
      },
    };
  }, [isHoveredBox]);

  return (
    <Wrapper>
      <InnerWrapper>
        <Title
          {...getStyle()}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) =>
            handleContentEditableChange(e, FacebookContentChangeHandler.NewsFeedDescriptionTitle)
          }>
          {newsFeedDescriptionTitle}
        </Title>
        <Subtitle
          {...getStyle()}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) =>
            handleContentEditableChange(
              e,
              FacebookContentChangeHandler.NewsFeedDescriptionSubtitle,
              carouselData[0].id
            )
          }>
          {newsFeedDescriptionSubtitle}
        </Subtitle>
        <BottomDescription
          {...getStyle()}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) =>
            handleContentEditableChange(
              e,
              FacebookContentChangeHandler.NewsFeedDescription,
              carouselData[0].id
            )
          }>
          {newsFeedDescription}
        </BottomDescription>
      </InnerWrapper>
      <Select value={newsFeedSelectValue} />
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.grayMid};
  padding: ${theme.spacing.spacing12} ${theme.spacing.spacing16};
  border-bottom: ${theme.borderWidth.small} ${theme.borderStyle.solid} ${theme.colors.grayMid};
  align-items: center;
  gap: ${theme.spacing.spacing10};
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
`
);

const InnerWrapper = styled.div(
  () => `
  width: fit-content
`
);

const Title = styled.div(
  ({ theme }) => `
color: ${theme.colors.grayNeutral};
font-size: ${theme.fontSizes.small};
font-weight: ${theme.fontWeights.normal};
overflow: hidden;
outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
width: fit-content;
word-break: break-word;
`
);

const Subtitle = styled.span(
  ({ theme }) => `
  color: ${theme.colors.blackMiddle};
  font-size: ${theme.fontSizes.mega};
  font-weight: ${theme.fontWeights.semibold};
  overflow: hidden;
  outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
  margin-bottom: ${theme.spacing.spacing3};
  width: fit-content;
  word-break: break-word;
`
);

const BottomDescription = styled.div(
  ({ theme }) => `
  color: ${theme.colors.grayNeutral};
  font-size: ${theme.fontSizes.big};
  font-weight: ${theme.fontWeights.normal};
  overflow: hidden;
  outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
  width: fit-content;
  word-break: break-word;
`
);
