import React from 'react';
import { FooterLisItem } from './FooterListItem';
import { styled } from 'styled-components';
import { StyledFlexRow } from '../../layout';

const data = [
  {
    id: 1,
    href: 'https://mockupbro.com/contact',
    name: 'Feedback',
  },
  {
    id: 2,
    href: '',
    name: 'License',
  },
];

export const FooterLisItems = () => {
  return (
    <Container>
      {data.map((item) => (
        <FooterLisItem key={item.id} href={item.href}>
          {item.name}
        </FooterLisItem>
      ))}
    </Container>
  );
};

const Container = styled(StyledFlexRow)(
  () => `
    gap: 15px;
`
);
