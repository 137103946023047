import { Loader } from 'components/Loader';
import { useFacebook } from 'context';
import React from 'react';
import { styled } from 'styled-components';

type ImagePreviewType = {
  placeholder: string | undefined;
  selectedImage: Blob | MediaSource | undefined;
};

export const ImagePreview: React.FC<ImagePreviewType> = ({ placeholder, selectedImage }) => {
  const { loading } = useFacebook();

  return loading ? (
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  ) : (
    <StyledImage
      src={selectedImage === undefined ? placeholder : URL.createObjectURL(selectedImage!)}
      alt="Facebook mockup"
    />
  );
};

const StyledImage = styled.img(
  () => `
    width: 100%;
    height: 100%;
    object-fit: cover;
  `
);

const LoadingContainer = styled.div(
  () => `
  width: 100%;
  height: 265px;
  display: flex;
  justify-content: center;
  align-items: center;

`
);
