import React, { useCallback, useMemo } from 'react';
import { useFacebook } from '../../../context';
import placeholder from '../../../assets/images/story-placeholder.png';
import { StoryAdFormat } from '../../../types';
import { useTheme } from '../../../hooks';

type StoryImagePreviewType = {
  selectedImage: Blob | MediaSource | undefined;
};

export const StoryImagePreview: React.FC<StoryImagePreviewType> = ({ selectedImage }) => {
  const { deviceType, storyFormat } = useFacebook();
  const theme = useTheme();

  const backgroundImage = useMemo(() => {
    return selectedImage === undefined ? placeholder : URL.createObjectURL(selectedImage!);
  }, [selectedImage]);

  const getActiveStyle = useCallback(() => {
    return {
      style: {
        width: selectedImage === undefined ? '200px' : '320px',
        height:
          storyFormat === StoryAdFormat.Cover
            ? '575px'
            : selectedImage === undefined
            ? '100px'
            : '240px',
        borderRadius: storyFormat === StoryAdFormat.Cover ? theme.borderRadius.big : '0',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: selectedImage === undefined ? 'contain' : 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: selectedImage === undefined ? 'no-repeat' : 'unset',
      },
    };
  }, [deviceType, storyFormat, selectedImage]);

  return <div {...getActiveStyle()} />;
};
