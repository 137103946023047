import React from 'react';

export const CommentIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      data-v-5fe68784=""
      width="18px"
      height="18px">
      <g>
        <path
          d="M424.8,31H87.2C56.2,31,31,56.2,31,87.2v253.1c0,31,25.2,56.2,56.2,56.2h84.4v73.8c0,6.2,5.1,10.5,10.5,10.5 c2.1,0,4.3-0.6,6.2-2.1l109.8-82.3h126.6c31,0,56.2-25.2,56.2-56.2V87.2C481,56.2,455.8,31,424.8,31z M438.8,340.4 c0,7.7-6.3,14.1-14.1,14.1H284.1l-11.2,8.4l-59.1,44.3v-52.7H87.2c-7.7,0-14.1-6.3-14.1-14.1V87.2c0-7.7,6.3-14.1,14.1-14.1h337.5 c7.7,0,14.1,6.3,14.1,14.1V340.4z"
          fill="#616770"></path>
      </g>
    </svg>
  );
};
