import React, { useCallback, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled } from 'styled-components';
import { CarouselPlaceholder } from './CarouselPlaceholder';

import { CarouselItem } from './CarouselItem';
import { CarouselArrow } from './CarouselArrow';
import { useFacebook } from '../../../context';
import { ArrowLeftIcon } from 'assets';
import { ArrowRightIcon } from 'assets';
import { DeleteModal } from 'components/DeleteModal';
import { BaseModalRef } from 'types';

export const Carousel = () => {
  const { carouselData, showAllSlides, isHoveredSlider, setIsHoveredSlider } = useFacebook();
  const deleteModalRef = useRef<BaseModalRef>(null);
  const settings = {
    infinite: false,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    prevArrow: (
      <CarouselArrow position="left" onClick={() => console.log()} icon={<ArrowRightIcon />} />
    ),
    nextArrow: (
      <CarouselArrow position="right" onClick={() => console.log()} icon={<ArrowLeftIcon />} />
    ),
  };

  const carouselPlaceholderHandler = useCallback(() => {
    if ((showAllSlides && isHoveredSlider) || isHoveredSlider) {
      return <CarouselPlaceholder />;
    } else {
      return <EmptyCarousel />;
    }
  }, [showAllSlides, isHoveredSlider]);

  return (
    <Wrapper
      onMouseEnter={() => setIsHoveredSlider(true)}
      onMouseLeave={() => setIsHoveredSlider(false)}>
      <StyledSlider showAll={showAllSlides} {...settings}>
        {carouselData.map((item) => {
          return (
            <CarouselItem onDelete={() => deleteModalRef.current?.open()} key={item.id} {...item} />
          );
        })}
        {carouselPlaceholderHandler()}
      </StyledSlider>
      <DeleteModal ref={deleteModalRef} />
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing.spacing0} ${theme.spacing.spacing0} ${theme.spacing.spacing0} ${theme.spacing.spacing5}
`
);

const StyledSlider = styled(Slider)<{ showAll: boolean }>(
  ({ showAll }) => `
.slick-list {
  overflow: ${showAll ? 'visible' : 'hidden'} !important;
  z-index: ${showAll ? '100' : '0'} !important;
}
`
);

const EmptyCarousel = styled.div(
  () => `
  min-width: 300px;
`
);
