import React from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';

interface MenuItemInterface {
  name: string;
  link: string;
  inner?: boolean;
  onClick?: () => void;
}

export const MenuItem: React.FC<MenuItemInterface> = ({ name, link, inner = false, onClick }) => {
  return (
    <StyledListElement>
      {inner ? (
        <InnerLink onClick={onClick} to={link}>
          {name}
        </InnerLink>
      ) : (
        <Link href={link}>{name}</Link>
      )}
    </StyledListElement>
  );
};

const StyledListElement = styled.li(
  () => `
  width: auto;
  @media(max-width: 1110px) {
    width: 100%
  }
`
);

const Link = styled.a(
  ({ theme }) => `
    display: block;
    font-size: ${theme.fontSizes.body};
    padding: ${theme.spacing.spacing18} ${theme.spacing.spacing20};
    color: hsla(0,0%,100%,.5);
    &:hover {
        background-color: ${theme.colors.thernary};
        border-bottom: ${theme.borderWidth.default} ${theme.borderStyle.solid} ${theme.colors.tertiray};
        color: hsla(0,0%,100%,.75)
      }
`
);

const InnerLink = styled(NavLink)(
  ({ theme }) => `
    display: block;
    font-size: ${theme.fontSizes.body};
    padding: ${theme.spacing.spacing18} ${theme.spacing.spacing20};
    color: hsla(0,0%,100%,.5);
    &:hover {
        background-color: ${theme.colors.thernary};
        border-bottom: ${theme.borderWidth.default} ${theme.borderStyle.solid} ${theme.colors.tertiray};
        color: hsla(0,0%,100%,.75)
      }
    &.active {
      background-color: ${theme.colors.thernary};
        border-bottom: ${theme.borderWidth.default} ${theme.borderStyle.solid} ${theme.colors.tertiray};
        color: hsla(0,0%,100%,.75)
    }

`
);
