// TYPES
export type DropdownItemProps = {
  id: number;
  name: string;
  value: { id: number; label: string; carouselItemId?: number };
  onClick: () => void;
  placeholder?: boolean;
};

export type SelectValues = {
  id: number;
  label: string;
};

export type NewsFeedItem = {
  id: number;
  newsFeedMainImage: File | Blob | undefined;
  newsFeedProfileImage: File | Blob | undefined;
  newsFeedSelectValue: SelectValues;
  newsFeedAddressTitle: string;
  newsFeedMainDescription: string;
  newsFeedDescriptionTitle: string;
  newsFeedDescriptionSubtitle: string;
  newsFeedDescription: string;
};

export type NewsFeedCarouselItem = {
  id: number;
  newsFeedMainImage: File | Blob | undefined;
  newsFeedSelectValue: SelectValues;
  newsFeedDescriptionSubtitle: string;
  newsFeedDescription: string;
};

export type StoryItem = {
  storyMainImage: File | Blob | undefined;
  storyProfileImage: File | Blob | undefined;
  storyAdreesTitle: string;
  storyDescription: string;
  storySelectValue: SelectValues;
};

export type BaseModalRef = {
  open: () => void;
  close: () => void;
};

// INTERFACES
export interface ScrollableDivRef {
  scrollWidth: number;
  clientWidth: number;
}

export interface IconInterface {
  color?: string;
  width?: number;
  height?: number;
}

// ENUMS
export enum Links {
  Devices = 'Devices',
  Print = 'Print',
  Apparel = 'Apparel',
  Packaging = 'Packaging',
  HomeDecor = 'Home & Decor',
  Social = 'Social Media',
  Feedback = 'Feedback',
  License = 'License',
}

export enum FacebookContentChangeHandler {
  NewsFeedAddressTitle = 'newsFeedAddressTitle',
  NewsFeedMainDescription = 'newsFeedMainDescription',
  NewsFeedDescriptionTitle = 'newsFeedDescriptionTitle',
  NewsFeedDescriptionSubtitle = 'newsFeedDescriptionSubtitle',
  NewsFeedDescription = 'newsFeedDescription',
  NewsFeedSelectValue = 'newsFeedSelectValue',

  StoryAddressTitle = 'storyAddressTitle',
  StoryDescription = 'storyDescription',
}

export enum FacebookCardTextValues {
  NewsFeedAddressTitle = 'Mockupbro.com',
  NewsFeedMainDescription = 'Try Mockupbro Now! Its Free',
  NewsFeedDescriptionTitle = 'MOCKUPBRO.COM',
  NewsFeedDescriptionSubtitle = 'Try Mockupbro Now! Its Free',
  NewsFeedDescription = 'With Mockupbro you can create ad mockups for free!',

  CarouselHeadlinePlaceholder = 'Headline',
  CarouselDescriptionPlaceholder = 'Description',

  StoryAddressTitle = 'Mockupbro.com',
  StoryDescription = 'Try Mockup Bro Now! Its Free',
}

export enum DeviceType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export enum FacebookAdType {
  NewsFeed = 'News Feed',
  LeadForm = 'Lead Form',
  Messenger = 'Messenger',
  Story = 'Story',
  Reel = 'Reel',
}

export enum SelectFormat {
  NewsFeed = 'NewsFeed',
  NewsFeedCarousel = 'NewsFeedCarousel',
  Story = 'Story',
}

export enum FacebookSettings {
  DeviceType = 'Device Type',
  AdType = 'Ad Type',
  AdFormat = 'Ad Format',
  AdOptions = 'Ad Options',
}

export enum FacebookImages {
  NewsFeedMainImage = 'newsFeedMainImage',
  NewsFeedProfileImage = 'newsFeedProfileImage',
  StoryMainImage = 'storyMainImage',
  StoryProfileImage = 'storyProfileImage',
  CarouselImage = 'carouselImage',
}

export enum StoryAdFormat {
  Original = 'Original',
  Cover = '1:1',
}

export enum NewsFeedAdFormat {
  SingleImage = 'Single Image',
  Carousel = 'Carousel',
}

export enum CarouselValues {
  ShowAll = 'Show All Carousel Images',
}

export enum ButtonVariants {
  primary = 'primary',
  transparent = 'transparent',
}

export enum ButtonValues {
  delete = 'Delete',
  download = 'Download',
  cancel = 'Cancel',
}
