import React from 'react';

export const CarouselIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      height="40px"
      width="40px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 62.811 62.811"
      xmlSpace="preserve">
      <g>
        <path
          fill="#5e72e4"
          d="M11.695,36.028l-0.831,1.64c-0.128,0.251-0.144,0.544-0.043,0.807
            c0.1,0.263,0.306,0.472,0.567,0.575l1.709,0.676l0.026,1.838c0.004,0.281,0.126,0.547,0.337,0.734
            c0.21,0.186,0.492,0.275,0.77,0.246l1.827-0.196l0.878,1.616c0.135,0.248,0.366,0.427,0.64,0.494
            c0.079,0.019,0.159,0.029,0.239,0.029c0.196,0,0.391-0.058,0.557-0.169l1.526-1.023l1.527,1.023
            c0.233,0.157,0.523,0.207,0.796,0.141c0.272-0.067,0.505-0.247,0.64-0.494l0.878-1.617l1.828,0.196
            c0.275,0.029,0.559-0.06,0.769-0.246c0.211-0.187,0.333-0.453,0.337-0.734l0.025-1.838l1.71-0.676
            c0.262-0.104,0.468-0.313,0.567-0.576c0.101-0.263,0.085-0.556-0.043-0.807l-0.832-1.64l1.2-1.394
            c0.184-0.213,0.269-0.494,0.235-0.773c-0.034-0.279-0.185-0.531-0.414-0.694l-1.499-1.065l0.416-1.791
            c0.063-0.274,0.008-0.563-0.151-0.794c-0.16-0.232-0.41-0.385-0.688-0.423l-1.822-0.248l-0.465-1.778
            c-0.071-0.272-0.254-0.502-0.503-0.632c-0.248-0.131-0.541-0.15-0.806-0.055l-1.728,0.627l-1.238-1.359
            c-0.379-0.416-1.1-0.416-1.479,0l-1.237,1.359l-1.729-0.627c-0.265-0.097-0.558-0.077-0.806,0.055
            c-0.249,0.131-0.432,0.36-0.503,0.632l-0.464,1.778l-1.822,0.248c-0.278,0.038-0.528,0.191-0.688,0.423
            c-0.159,0.231-0.215,0.519-0.151,0.793l0.416,1.791l-1.498,1.065c-0.229,0.163-0.38,0.415-0.414,0.694
            c-0.033,0.279,0.052,0.56,0.235,0.773L11.695,36.028z M14.469,34.905c0-2.994,2.436-5.43,5.429-5.43c2.994,0,5.43,2.435,5.43,5.43
            c0,2.994-2.435,5.43-5.43,5.43C16.904,40.335,14.469,37.899,14.469,34.905z"
        />
        <path
          fill="#5e72e4"
          d="M59.811,5.899h-46.76c-1.657,0-3,1.343-3,3v7.166H3c-1.657,0-3,1.343-3,3v34.848
            c0,1.657,1.343,3,3,3h46.76c1.657,0,3-1.343,3-3v-7.166h7.052c1.657,0,3-1.343,3-3V8.899C62.811,7.242,61.469,5.899,59.811,5.899z
             M46.76,22.065v13.471c-3.5-3.779-8.816-4.439-14.469-4.633c-0.512-0.019-0.914,0.32-1.013,0.795
            c-0.1,0.477,0.158,0.955,0.61,1.134c2.692,1.067,4.26,2.342,4.932,4.011c1.266,3.144-1.037,5.846-3.394,7.205
            c-4.367,2.519-9.939,2.81-13.749,2.81c-0.798,0-1.587-0.015-2.359-0.038C13.252,46.7,9.285,46.141,6,45.578V22.065H46.76z
             M56.811,40.747H52.76V19.065c0-1.657-1.343-3-3-3H16.052v-4.166h40.76V40.747z"
        />
      </g>
    </svg>
  );
};
