import React, { useCallback, useState } from 'react';
import { styled, useTheme } from 'styled-components';
import { DropdownItemProps } from '../../types';
import { useFacebook } from '../../context';

export const DropdownItem: React.FC<DropdownItemProps> = ({
  id,
  name,
  onClick,
  value,
  placeholder,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { selectValueHandler } = useFacebook();
  const theme = useTheme();

  const clickHandler = () => {
    if (value.carouselItemId !== undefined) {
      if (placeholder) {
        selectValueHandler(id, name, value.carouselItemId, true);
      } else {
        selectValueHandler(id, name, value.carouselItemId);
      }
    } else {
      selectValueHandler(id, name);
    }
    onClick();
  };

  const getActiveStyle = useCallback(() => {
    if (isHovered) {
      return {
        style: {
          backgroundColor: theme.colors.white,
          color: theme.colors.gray,
        },
      };
    }
    if (value.id === id) {
      return {
        style: {
          backgroundColor: theme.colors.grayLight,
          color: theme.colors.gray,
        },
      };
    } else {
      return {
        style: {
          backgroundColor: theme.colors.gray,
          color: theme.colors.white,
        },
      };
    }
  }, [value, isHovered]);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={clickHandler}>
      <Label {...getActiveStyle()}>{name}</Label>
    </div>
  );
};

const Label = styled.p(
  ({ theme }) => `
    cursor: pointer;
    padding: ${theme.spacing.spacing6} ${theme.spacing.spacing10};
`
);
