import React from 'react';

export const HamburgerIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27px" height="27px">
      <path
        stroke="rgba(255, 255, 255, 0.5)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M4 7h22M4 15h22M4 23h22"
      />
    </svg>
  );
};
