import React, { useCallback } from 'react';
import { styled } from 'styled-components';

interface SelectableBoxInterface {
  active: boolean;
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}

export const SelectableBox: React.FC<SelectableBoxInterface> = ({
  active,
  icon,
  text,
  onClick,
}) => {
  const getStyle = useCallback(() => {
    return {
      style: {
        opacity: active ? 1 : 0.3,
        background: active ? '#5e72e440' : 'transparent',
      },
    };
  }, [active]);

  return (
    <Wrapper {...getStyle()} onClick={onClick}>
      <IconWrapper>{icon}</IconWrapper>
      <StyledText>{text}</StyledText>
    </Wrapper>
  );
};

const Wrapper = styled.div(
  ({ theme }) => `
  width: 90px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: ${theme.borderWidth.small} ${theme.borderStyle.solid} ${theme.colors.secondary};
  border-radius: ${theme.borderRadius.micro};
  cursor: pointer;
  @media(max-width: 991px) {
    width: 100px;
    height: 90px;
  }
`
);

const IconWrapper = styled.div(
  ({ theme }) => `
  padding-top: ${theme.spacing.spacing10}
`
);

const StyledText = styled.p(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.spacing4};
  font-weight: ${theme.fontWeights.semibold};
  color: ${theme.colors.secondary};
`
);
