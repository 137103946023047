import React, { useCallback, useEffect, useRef } from 'react';
import { data } from './data';
import { styled } from 'styled-components';
import { DropdownItem } from './DropdownItem';
import { useFacebook } from 'context';
import { NewsFeedAdFormat } from 'types';

interface DropdownInterface {
  visible: boolean;
  setVisible: (arg: boolean) => void;
  value: { id: number; label: string; carouselItemId?: number };
  placeholder?: boolean;
}

export const Dropdown: React.FC<DropdownInterface> = ({
  visible,
  setVisible,
  value,
  placeholder,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { newsFeedFormat } = useFacebook();

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node | null)) {
      setVisible(false);
    }
  };

  const styleHandler = useCallback(() => {
    return {
      style: {
        maxHeight: newsFeedFormat === NewsFeedAdFormat.Carousel ? '230px' : '400px',
      },
    };
  }, [newsFeedFormat]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setVisible(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return visible ? (
    <Wrapper {...styleHandler()} ref={dropdownRef}>
      {data.map(({ id, label }) => (
        <DropdownItem
          placeholder={placeholder}
          value={value}
          onClick={() => {
            setVisible(false);
          }}
          key={id}
          id={id}
          name={label}
        />
      ))}
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div(
  ({ theme }) => `
    background: ${theme.colors.gray};
    position: absolute;
    right: 0;
    z-index: 50;
    bottom: 100%;
    border-radius: ${theme.borderRadius.default};
    
    overflow-y: auto; 
    overflow-x: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`
);
