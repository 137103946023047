import React from 'react';
import { styled } from 'styled-components';
type TriggerProps = {
  onClick: () => void;
  icon: React.ReactNode;
};
export const Trigger: React.FC<TriggerProps> = ({ onClick, icon }) => {
  return <StyledTrigger onClick={onClick}>{icon}</StyledTrigger>;
};
const StyledTrigger = styled.div`
  cursor: pointer;
`;
