import React, { useCallback } from 'react';
import { useFacebook } from '../../../context';
import styled from 'styled-components';
import { FacebookContentChangeHandler } from 'types';
import { useTheme } from 'hooks';

export const CarouselPlaceholderDescription = () => {
  const theme = useTheme();
  const { placeholderData, editPlaceholderNewsFeedItem, isHoveredBox } = useFacebook();
  const { newsFeedDescription } = placeholderData;

  const getStyle = useCallback(() => {
    return {
      style: {
        outlineColor: isHoveredBox ? `${theme.colors.gray}` : `${theme.colors.transparent}`,
      },
    };
  }, [isHoveredBox]);

  return (
    <Wrapper
      {...getStyle()}
      contentEditable
      suppressContentEditableWarning
      onBlur={(e) =>
        editPlaceholderNewsFeedItem(
          FacebookContentChangeHandler.NewsFeedDescription,
          (e.target as HTMLDivElement).innerHTML
        )
      }>
      {newsFeedDescription}
    </Wrapper>
  );
};

const Wrapper = styled.p(
  ({ theme }) => `
  color: ${theme.colors.grayNeutral};
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.normal};
  outline: ${theme.borderWidth.small} ${theme.borderStyle.dashed} ${theme.colors.transparent};
  white-space: nowrap;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: calc(${theme.spacing.spacing16} * 2);
  line-height: ${theme.spacing.spacing16};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  `
);
