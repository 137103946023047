import React from 'react';
import { useFacebook } from '../../../context';
import { FacebookAdType } from '../../../types';
import { DeviceSize } from './DeviceSize';

export const DeviceSizeWrapper = () => {
  const { adType } = useFacebook();
  switch (adType) {
    case FacebookAdType.NewsFeed:
    case FacebookAdType.LeadForm:
      return <DeviceSize />;
    case FacebookAdType.Messenger:
    case FacebookAdType.Reel:
    case FacebookAdType.Story:
      return null;
    default:
      return <DeviceSize />;
  }
};
