import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

interface SwitchInterface {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Switch: React.FC<SwitchInterface> = ({ label, checked, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <SwitchContainer>
      <SwitchInput checked={checked} onChange={handleChange} />
      {label && <SwitchLabel>{label}</SwitchLabel>}
    </SwitchContainer>
  );
};

const SwitchContainer = styled.label(
  ({ theme }) => `
display: flex;
align-items: center;
cursor: pointer;
gap: ${theme.spacing.spacing8}
`
);

const SwitchLabel = styled.span(
  ({ theme }) => `
  margin-left: ${theme.spacing.spacing5};
  font-weight: ${theme.fontWeights.semibold}
`
);

const SwitchInput = styled.input.attrs({ type: 'checkbox' })<{ checked: boolean }>(
  ({ checked, theme }) => `
position: relative;
width: 45px;
height: 22px;
appearance: none;
background-color: ${theme.colors.grayLight};
border-radius: ${theme.borderRadius.large};
outline: none;
cursor: pointer;

&::before {
  content: '';
  position: absolute;
  width: ${theme.spacing.spacing25};
  height: ${theme.spacing.spacing25};
  background-color: ${checked ? theme.colors.secondary : theme.colors.gray};
  border-radius: ${theme.borderRadius.half};
  top: ${theme.spacing.negative1};
  left: ${theme.spacing.negative1};
  transition: 0.2s;
}

&:checked::before {
  left: calc(100% - 18px);
}
`
);
